import Header from "./shared/Header";
import Footer from "./shared/Footer";
import { Link } from "react-router-dom";
import Banner from "./shared/Banner";
import {useEffect} from "react";
import appConstant from "./shared/constant/constant.json";

function Thanks() {
    useEffect(() => {
        localStorage.removeItem('cart');
    }, []);
    return (
        <>
            <Header/>
            <Banner name='order placed' parentSlug='/' parent='Home'/>
            <div className='order-status text-center'>
                <div className="container">
                    <div className='row align-items-center'>
                        <div className='col-md-6 mb-4'>
                            <img className='order-success' src={`${appConstant.CDN_URL}/assets/images/order-success.png`} alt='order-success'/>
                        </div>
                        <div className='col-md-6'>
                            <h6 className='heading'>Order Confirmed</h6>
                            <p className='content'> Thank you for shopping at Sage Controllers.
                            A performa invoice has been emailed to you. An final invoice will be emailed soon after allocations are complete. Please shop with us again. </p>  
                            <div className="text-center">
                                <Link to='/shop' className="btn-purple btn mt-4">&#129052; Back to Shopping</Link>
                            </div>
                        </div>
                    </div>  
                </div>          
            </div>
            <Footer/>
        </>
    );
  }
  
  export default Thanks;