import { Link } from "react-router-dom";
import appConstant from "./constant/constant.json";

function ProductCard(props) {
    const data = props.data;
    const getPercentage = (regularPrice, sale_price) => {
        const mrp = parseFloat(regularPrice);
        const salePrice = parseFloat(sale_price);
        if (!isNaN(mrp) && !isNaN(salePrice) && salePrice < mrp) {
            const discount = mrp - salePrice;
            const discountPercentage = ((discount / mrp) * 100).toFixed(2);
            return discountPercentage;
        } else {
            return 0;
        }
    }

    return (
        <>
        
            <div className="wsk-cp-product">
                <div className="wsk-cp-img">
                <a href={'/product/' + data?.slug}><img src={`${appConstant.IMAGE_URL}/products/` + data?.featured_image} alt={data?.title} className="img-responsive" /></a>
                </div>
                <div className="wsk-cp-text">
                <div className="category">
                {data?.version_name ? 
                <span>{data?.version_name}</span>
                 : ''}
                </div>
                <div className="title-product">
                    <a href={'/product/' + data?.slug}><h3>{data?.title}</h3></a>
                </div>
                <div className="card-footer bg-transparent">
                    <div className="wcf-left">{data.sale_price ? <span className="salePrice">&#x20B9; {data.sale_price}</span> : ''} 
                    {(data.sale_price && parseInt(data.sale_price) < parseInt(data.price)) ? <><span className="price strikeText"> MRP <span>&#x20B9; {data.price}</span></span> <span className="discount">({getPercentage(data?.price, data.sale_price)}%)</span></> : ''}
                    </div>
                    {/* <div className="wcf-right"><span className="buy-btn"><i className="fa-solid fa-cart-shopping"></i></span></div> */}
                </div>

                </div>
            </div>
        </>
        );
}

export default ProductCard;