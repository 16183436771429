import Header from "./shared/Header";
import Footer from "./shared/Footer";
import Faqs from "./shared/Faqs";
import Banner from "./shared/Banner";

function FaqsPage() {
    return (
        <>
            <Header/>
            <Banner name='Faqs' parentSlug='/' parent='Home'/>
            
            <div className="faqsSection py-5">
                <div className="container">
                    <h4 className="h1 mb-2  text-center">Frequently Asked Questions</h4>
                    <h4 className="h5  text-center mb-5">Browse our FAQ page for answers to common queries and helpful information about our products and services.</h4>
                    <Faqs page='4'/>
                </div>
            </div>
            
            <Footer/>
        </>
    );
  }
  
  export default FaqsPage;
  