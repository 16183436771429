import React, {useEffect, useState} from 'react';
import Header from "./shared/Header";
import Footer from "./shared/Footer";
import Banner from "./shared/Banner";
import {Link, useParams} from "react-router-dom";
import axios from "axios";
import appConstant from "./shared/constant/constant.json";
import moment from 'moment';
import LoadingSpinner from './shared/LoadingSpinner.js';

function BlogDetail() {
    const [isLoadingBlogs, setIsLoadingBlogs] = useState(true);
    const props = useParams();
    const [blog, setBlog] = useState({});
    useEffect(() => {
        getBlogData(); 
    }, []);
    const params = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      }
    const getBlogData = () => {
        axios.get(`${appConstant.API_URL}/c/blog_details/${props.slug}`, params)
            .then(res => {
                setIsLoadingBlogs(false);
                if(res?.data?.status === 200){
                    setBlog(res?.data?.data);
                }else{
                    setBlog({});
                }
            })
            .catch(error => {
                setIsLoadingBlogs(true);
            });
    }
    return (
        <>
            <Header/> 
            <Banner name={blog.blog_title} parentSlug='/' parent='Home' subParent='blogs' subParentSlug='/blogs'/>
            {isLoadingBlogs ? (
                        <LoadingSpinner />
                        ) : (
                        <>
            <section className="product-section py-5">
                <div className="container blog-detail ">                    
                    <h2 className='blog-title'>{blog.blog_title}</h2>
                    <p className='blog-publish'>Published on {moment(blog.date_time).format('MMMM D, YYYY')}</p>
                    <div>
                    <img className="mb-3 blog-detail-image me-4" src={`${appConstant.IMAGE_URL}/blogs/` + blog?.blog_image} alt={blog?.title}/>
                    <div dangerouslySetInnerHTML={{__html: blog?.blog_description}} className="blog-content"></div>
                    </div>
                    <div className="text-center">
                        <Link to="/blogs" className="btn-login login-2">View All Blogs</Link>
                    </div>
                </div>
            </section>
            </>
                        )}
            <Footer/>
        </>
    );
  }
  
  export default BlogDetail;