import React, {useEffect, useState} from 'react';
import Header from "./shared/Header";
import Footer from "./shared/Footer";
import {useParams} from "react-router-dom";
import axios from "axios";
import appConstant from "./shared/constant/constant.json";
function Services() {
    const props = useParams();
    const [comp, setComp] = useState(appConstant.SITE_URL + '/assets/images/p1.png');
    const changeImage = (value) =>{
        setComp(value);
    }
    const [inputPaddle, setInputPaddle] = useState({
        upperLeft : "",
        upperRight : "",
        lowerLeft : "",
        lowerRight : ""
    });
    const [userData, setUserData] = useState({
        service_type: 0,
        controller_type: '',
        version_id:'',
        version_type: '',
        price: 0,
        design_id: '',
        message: '',
        mappingData: {
            upperLeft : "",
            upperRight : "",
            lowerLeft : "",
            lowerRight : ""
        }
    });
    const [isRepair, setIsRepair] = useState(false);
    const [description, setDescription] = useState('');
    const [disableBuyButton, setDisableBuyButton] = useState(true);
    const [ps4, setPs4] = useState([]);
    const [ps5, setPs5] = useState([]);
    const [xbox, setXbox] = useState([]);
    const [serviceData, setServiceData] = useState([]);
    const [designs, setDesigns] = useState([]);
    const [sliders, setSliders] = useState([]);
    const [fixedMappingErrorMessage, setFixedMappingErrorMessage] = useState('')
    const [remapPrice, setRemapPrice] = useState(0);
    const [salePrice, setSalePrice] = useState(0);
    const [designPrice, setDesignPrice] = useState(0);
    const [selectDesign, setSelectDesign] = useState(0);
    const handlePaddle = (e) => {
        const {name, value} = e.target;
        setInputPaddle({...inputPaddle, [name]: value});
    }

    const [mappingFormFilled, setMappingFormFilled] = useState(false);
    const saveMappingForm = () => {
        if(inputPaddle.lowerLeft === '' || inputPaddle.lowerRight === '' || inputPaddle.upperLeft === '' || inputPaddle.upperRight === ''){
            setFixedMappingErrorMessage('All fields are mandatory.');
            return false;
        }else{
            const data = {...userData};
            data.mappingData = {
                upperLeft : inputPaddle.upperLeft,
                upperRight : inputPaddle.upperRight,
                lowerLeft : inputPaddle.lowerLeft,
                lowerRight : inputPaddle.lowerRight
            }
            setUserData(data);
            setMappingFormFilled(true);
            setDisableBuyButton(false)
        }
    }
    const showMappingForm = () => {
        setMappingFormFilled(false);
    }
    useEffect(() => {
        if(props?.type === 'repair-and-customize'){
            setIsRepair(true);
            serviceVersion(1, 'ps5')
            serviceVersion(1, 'ps4')
            serviceVersion(1, 'xbox')
            setUserData({...userData, ['service_type']: 1});
        }else{
            serviceVersion(2, 'ps5')
            serviceVersion(2, 'ps4')
            serviceVersion(2, 'xbox')
            setUserData({...userData, ['service_type']: 2});
        }
    }, []);
    const params = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
    }
    const serviceVersion = (version, controllerType) => {
        axios.get(`${appConstant.API_URL}/c/service/${version}/${controllerType}`, params)
            .then(res => {
                if(res?.data?.status === 200){
                    if(controllerType === 'ps5'){
                        setPs5(res?.data?.data);
                    } else if(controllerType === 'ps4'){
                        setPs4(res?.data?.data);
                    } else{
                        setXbox(res?.data?.data);
                    }
                }
            })
            .catch(error => {
                //   setIsLoading(true);
            });
    }
    const showVersions = (version) => {
        setDescription('')
        setDesigns([]);
        const myData = userData;
        myData.controller_type = version;
        myData.price = 0;
        setUserData(myData);
        if(version === 'ps4'){
            setServiceData(ps4);
        }else if(version === 'ps5'){
            setServiceData(ps5);
        }else{
            setServiceData(xbox);
        }
    }
    const showDesigns = (designs) => {
        setDescription(designs?.description);
        const preUserData = {...userData};
        preUserData.version_id = designs?.id;
        preUserData.price = parseInt(designs?.sale_price);
        setUserData(preUserData);
        setSalePrice(parseInt(designs?.sale_price));
        setRemapPrice(parseInt(designs.remap_price));
        setDesigns(designs);
    }
    const showSlider = (sliderData) => {
        setSelectDesign(sliderData?.id);
        const preUserData = {...userData};
        preUserData.design_id = sliderData?.id;
        setDesignPrice(parseInt(sliderData.price));
        let userRemapPrice = 0;
        if(preUserData.version_type === 'remap'){
            userRemapPrice = remapPrice;
        }
        const newPrice = salePrice + userRemapPrice + parseInt(sliderData.price);
        preUserData.price = newPrice
        setUserData(preUserData);
        const data = [];
        if(sliderData.featured_image){data.push(sliderData.featured_image); setComp(sliderData.featured_image)}
        if(sliderData.image1){data.push(sliderData.image1)}
        if(sliderData.image2){data.push(sliderData.image2)}
        if(sliderData.image3){data.push(sliderData.image3)}
        if(sliderData.image4){data.push(sliderData.image4)}
        setSliders(data);
    }
    const showVersionType = (val) => {
        const data = userData;
        data.version_type = val;
        if(val === 'remap'){
            setDisableBuyButton(false);
            setMappingFormFilled(false);
            const includeRemapPrice = parseInt(salePrice) + parseInt(remapPrice) + designPrice;
            data.price = includeRemapPrice;
        }else{
            data.price = parseInt(salePrice) + designPrice;
            if(inputPaddle.lowerLeft === '' || inputPaddle.lowerRight === '' || inputPaddle.upperLeft === '' || inputPaddle.upperRight === ''){
                setFixedMappingErrorMessage('All fields are mandatory.');
                setDisableBuyButton(true)
                return false;
            }else{
                setMappingFormFilled(true);
                setDisableBuyButton(false);

            }
        }
        setUserData(data);
    }

    
    const [buyNowValidation, setBuyNowValidation] = useState({
        controller_type : '',
        design_id : '',
        version_id : '',
        version_type : ''
    });


   
    const checkBuyNowValidation = () => {
         const validation = userData;
        let errors = JSON.parse(JSON.stringify(buyNowValidation));
        
        if (!validation.controller_type) {
            errors.controller_type = "*Choose Controller";
        } else {
            errors.controller_type = "";
        }

        if (!validation.design_id) {
            errors.design_id = "*Choose Your Design";
        } else {
            errors.design_id = "";
        }

        if (!validation.version_id) {
            errors.version_id = "*Choose Controller Version";
        } else {
            errors.version_id = "";
        }

        if (!validation.version_type) {
            errors.version_type = "*Please Select Fixed Mapping/Remap Chip";
        } else {
            errors.version_type = "";
        }

        setBuyNowValidation(errors);

        if(errors.controller_type === '' && errors.design_id === '' && errors.version_id === '' && errors.version_type === ''){
            return true;
        }else{
            return false;
        }
    }
    const buyNow = () => {
        const success = checkBuyNowValidation(); 

        if(success){
            localStorage.setItem('service', JSON.stringify(userData));
            if(isRepair){
                window.location.href = '/checkout/3';
            }else{
                window.location.href = '/checkout/2';
            }
        }
    }
    const handleMessage = (e) => {
        setUserData({...userData, ['message']: e.target.value});
    }
    return (
        <>
            <Header id={'5'}/>
            <div className="short-banner"></div>
            <div className="single-product background">
                <div className="row m-0 p-0">
                    <div className="col-md-6  mb-4">
                        {/* <div id="singleProduct" data-bs-interval="false" className="carousel slide" data-bs-ride="carousel">
                            <div className="row">
                                <div className="col-sm-1 p-0 order-2 order-sm-1">                                        
                                    <ul className="carousel-indicators d-sm-flex d-none">
                                        <li data-bs-target="#singleProduct" data-bs-slide-to="0" className="active">
                                            <img src={`${appConstant.CDN_URL}/assets/images/custom1.jpg`} alt="title" className="thumb"/>
                                        </li>
                                        <li data-bs-target="#singleProduct" data-bs-slide-to="1" className="active">
                                            <img src={`${appConstant.CDN_URL}/assets/images/custom2.jpg`} alt="title" className="thumb"/>
                                        </li>
                                        <li data-bs-target="#singleProduct" data-bs-slide-to="2" className="active">
                                            <img src={`${appConstant.CDN_URL}/assets/images/custom3.jpg`} alt="title" className="thumb"/>
                                        </li>
                                    </ul>
                                    <div className="carousel-indicators d-sm-none d-flex">
                                        <button type="button" data-bs-target="#singleProduct" data-bs-slide-to='0' className="active"></button>
                                        <button type="button" data-bs-target="#singleProduct" data-bs-slide-to="1"></button>
                                        <button type="button" data-bs-target="#singleProduct" data-bs-slide-to="2"></button>
                                    </div>
                                </div>
                                <div className="col-sm-11 order-1 order-sm-2">
                                    <div className="position-relative">
                                        <div className="carousel-inner">
                                        <div className="carousel-item active">
                                            <img src={`${appConstant.CDN_URL}/assets/images/custom1.jpg`} alt="Los Angeles" className="d-block w-100"/>
                                        </div>
                                        <div className="carousel-item">
                                            <img src={`${appConstant.CDN_URL}/assets/images/custom2.jpg`} alt="Chicago" className="d-block w-100"/>
                                        </div>
                                        <div className="carousel-item">
                                            <img src={`${appConstant.CDN_URL}/assets/images/custom3.jpg`} alt="New York" className="d-block w-100"/>
                                        </div>
                                        </div>                                        
                                        <button className="carousel-control-prev" type="button" data-bs-target="#singleProduct" data-bs-slide="prev">
                                        <span className="carousel-control-prev-icon"></span>
                                        </button>
                                        <button className="carousel-control-next" type="button" data-bs-target="#singleProduct" data-bs-slide="next">
                                        <span className="carousel-control-next-icon"></span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        {comp ?
                        <div className='product-image'>
                            <img className='single-image' src={comp ? comp : `${appConstant.CDN_URL}/assets/images/p1.png`} alt='product' />
                            <div className='thumbnails'>
                                {sliders?.length > 0 && sliders?.map((res, i)=>
                                    <div className='thumb' key={'slider' + i}>
                                        <img src={res} alt={'product'+ i } onClick={() => changeImage(res)}/>
                                    </div>
                                )}

                            </div>
                        </div>
                        : ""
                        }
                    </div>
                    <div className="col-md-6">
                        <div className="bg-white border border-1 p-4">
                            <h4 className="h2 mb-2"><strong>{isRepair ? ('Repair/Customize') : ('Send in Your Controller') }</strong></h4>
                            {isRepair ?  (<p className="mb-3">Welcome to our one-stop gaming controller hub! In addition to our renowned "Send In Your Controller" service, we take pride in offering comprehensive repair and customization solutions for your gaming gear. Give your existing controller a new lease on life with our "Send In Your Controller -Repair & Customise" service.</p>) : (<p className="mb-3">Just package your controller securely, send it to us, and leave the rest to our expert team. Our customer-friendly process ensures that you have a smooth experience from start to finish. </p>)}

                            <h4 className="h4 mb-2"><strong>Choose Your Controller</strong></h4>
                            <div className="mb-3 d-flex">
                                {ps4?.length > 0 && (<div className="radio-option">
                                    <input type='radio' name='controller_type' value='ps4' onClick={() =>{showVersions('ps4')}}/>
                                    <label>PS4</label>
                                </div>)}
                                {ps5?.length > 0 && (<div className="radio-option">
                                    <input type='radio' name='controller_type' value='ps5'  onClick={() =>{showVersions('ps5')}}/>
                                    <label>PS5</label>
                                </div>)}
                                {xbox?.length > 0 && (<div className="radio-option">
                                    <input type='radio' name='controller_type' value='xbox'  onClick={() =>{showVersions('xbox')}}/>
                                    <label>XBOX</label>
                                </div>)}                               

                            </div>

                            
                            {/* for repair and customize */}
                            {isRepair && (<textarea className="form-control mb-3 h-auto" rows={'5'} name='message' placeholder="Mention all the problem/issues you are facing with your controller in detail and separate performa invoice for repairs will shared with you within 24 hours." value={userData.message} onChange={(e) => {handleMessage(e)}}></textarea>)}
                            {/* for repair and customize end */}

                            {serviceData?.length > 0 && (
                            <> <h4 className="h4 mb-2"><strong>Choose Controller Version</strong></h4>
                            <div className="mb-3 d-flex">
                                {serviceData?.map((res, i) =>
                                    <div className="radio-option" key={'version' + i}>
                                        <input type='radio' name='controller_version' value={res?.controller_version} onClick={() => {showDesigns(res)}}/>
                                        <label>{res?.controller_version}</label>
                                    </div>
                                )}
                            </div>
                            </>
                            )}
                           
                            <div dangerouslySetInnerHTML={{__html: description}}></div>
                            {designs.designs?.length > 0 && (
                                <><h4 className="h4 mb-2"><strong>Choose Style</strong></h4>
                                <div className="color-options">
                                    {designs.designs?.map((res, i) =>
                                        <div className="color-box" key={'designs' + i} onClick={() => {showSlider(res, )}}>
                                            <input className="color-input" checked={selectDesign === res.id ? true : false} type="radio" name="styleName" value="ash"/>
                                            <label className="color-label" >
                                                <img className="color-image" src={res.featured_image} alt="color"/>
                                                <span className="color-name">{res.title}</span>
                                                <span className="tooltiptext">&#x20B9; +{res.price}</span>
                                            </label>
                                        </div>
                                    )}

                                </div>
                                </>
                            )}

                             {serviceData?.length > 0 && (
                                <>
                            <div className="mb-3 d-flex">
                                <div className="radio-option">
                                    <input type='radio' name='mapping' value='fixed-mapping' onClick={() => {showVersionType('fixed-mapping')}}/>
                                    <label >Fixed Mapping</label>
                                </div>
                                <div className="radio-option">
                                    <input type='radio' name='mapping' value='remap' onClick={() => {showVersionType('remap')}}/>
                                    <label >Remap Chip <span className="tooltiptext">&#x20B9; +{remapPrice}</span></label>
                                </div>
                            </div>
                            </>
                            )}
                            {userData.version_type === 'fixed-mapping' && (
                            <div className="fixed-mapping-form">                                                                        
                                { mappingFormFilled ? 
                                <>
                                    <div>
                                        <h5 className='h5 mb-3'><strong>Mapping Choosed</strong></h5>
                                        <ul className='mb-3'>
                                            <li><h6><strong>Upper left hand paddle: </strong>{inputPaddle.upperLeft}</h6></li>
                                            <li><h6><strong>Upper right hand paddle: </strong>{inputPaddle.upperRight}</h6></li>
                                            <li><h6><strong>Lower left hand paddle: </strong>{inputPaddle.lowerLeft}</h6></li>
                                            <li><h6><strong>Lower right hand paddle: </strong>{inputPaddle.lowerRight}</h6></li>
                                        </ul>
                                        <button className="btn btn-purple" type='button' onClick={showMappingForm}>Edit Mapping</button>
                                    </div>
                                </>
                                : 
                                
                                <>
                                    <div className='fixed-map-image'>
                                        <img className="basic-image" alt='basic' src={`${appConstant.CDN_URL}/assets/images/fixed-mapping.png`} />
                                        {/* Upper left hand paddle  */}
                                        <h5 className='upper-left'>{inputPaddle.upperLeft}</h5>
                                        {/* Lower left hand paddle  */}
                                        <h5 className='lower-left'>{inputPaddle.lowerLeft}</h5>
                                        {/* Upper right hand paddle  */}
                                        <h5 className='upper-right'>{inputPaddle.upperRight}</h5>
                                        {/* Lower right hand paddle  */}
                                        <h5 className='lower-right'>{inputPaddle.lowerRight}</h5>
                                    </div>
                                    <p className="mb-3">Personalize button assignments for each of the 4 paddles with our Fixed Mapping Options</p>
                                    <strong className="d-block mb-1">Upper left hand paddle </strong>
                                    <select className="form-control px-2 py-1 w-100 mb-2" name='upperLeft' value={inputPaddle.upperLeft} onChange={(e) => handlePaddle(e)}>
                                        <option value={''}>To Be Selected</option>
                                        <option value={'CrossButton(X)'}>Cross Button (X)</option>
                                        <option value={'CircleButton(O)'}>Circle Button (O)</option>
                                        <option value={'SquareButton'}>Square Button </option>
                                        <option value={'TriangleButton'}>Triangle Button </option>
                                        <option value={'L3Button'}>L3 Button </option>
                                        <option value={'R3Button'}>R3 Button </option>
                                        <option value={'OptionButton'}>Option Button </option>
                                        <option value={'ShareButton'}>Share Button </option>
                                        <option value={'D-padLeft'}>D-pad Left </option>
                                        <option value={'D-padRight'}>D-pad Right </option>
                                        <option value={'D-padUp'}>D-pad Up </option>
                                        <option value={'D-padDown'}>D-pad Down </option>
                                    </select>
                                    <strong className="d-block mb-1">Upper right hand paddle </strong>
                                    <select className="form-control px-2 py-1 w-100 mb-2" name='upperRight' value={inputPaddle.upperRight} onChange={(e) => handlePaddle(e)}>
                                        <option value={''}>To Be Selected</option>
                                        <option value={'CrossButton(X)'}>Cross Button (X)</option>
                                        <option value={'CircleButton(O)'}>Circle Button (O)</option>
                                        <option value={'SquareButton'}>Square Button </option>
                                        <option value={'TriangleButton'}>Triangle Button </option>
                                        <option value={'L3Button'}>L3 Button </option>
                                        <option value={'R3Button'}>R3 Button </option>
                                        <option value={'OptionButton'}>Option Button </option>
                                        <option value={'ShareButton'}>Share Button </option>
                                        <option value={'D-padLeft'}>D-pad Left </option>
                                        <option value={'D-padRight'}>D-pad Right </option>
                                        <option value={'D-padUp'}>D-pad Up </option>
                                        <option value={'D-padDown'}>D-pad Down </option>
                                    </select>
                                    <strong className="d-block mb-1">Lower left hand paddle </strong>
                                    <select className="form-control px-2 py-1 w-100 mb-2" name='lowerLeft' value={inputPaddle.lowerLeft} onChange={(e) => handlePaddle(e)}>
                                        <option value={''}>To Be Selected</option>
                                        <option value={'CrossButton(X)'}>Cross Button (X)</option>
                                        <option value={'CircleButton(O)'}>Circle Button (O)</option>
                                        <option value={'SquareButton'}>Square Button </option>
                                        <option value={'TriangleButton'}>Triangle Button </option>
                                        <option value={'L3Button'}>L3 Button </option>
                                        <option value={'R3Button'}>R3 Button </option>
                                        <option value={'OptionButton'}>Option Button </option>
                                        <option value={'ShareButton'}>Share Button </option>
                                        <option value={'D-padLeft'}>D-pad Left </option>
                                        <option value={'D-padRight'}>D-pad Right </option>
                                        <option value={'D-padUp'}>D-pad Up </option>
                                        <option value={'D-padDown'}>D-pad Down </option>
                                    </select>
                                    <strong className="d-block mb-1">Lower right hand paddle </strong>
                                    <select className="form-control px-2 py-1 w-100 mb-2" name='lowerRight' value={inputPaddle.lowerRight} onChange={(e) => handlePaddle(e)}>
                                        <option value={''}>To Be Selected</option>
                                        <option value={'CrossButton(X)'}>Cross Button (X)</option>
                                        <option value={'CircleButton(O)'}>Circle Button (O)</option>
                                        <option value={'SquareButton'}>Square Button </option>
                                        <option value={'TriangleButton'}>Triangle Button </option>
                                        <option value={'L3Button'}>L3 Button </option>
                                        <option value={'R3Button'}>R3 Button </option>
                                        <option value={'OptionButton'}>Option Button </option>
                                        <option value={'ShareButton'}>Share Button </option>
                                        <option value={'D-padLeft'}>D-pad Left </option>
                                        <option value={'D-padRight'}>D-pad Right </option>
                                        <option value={'D-padUp'}>D-pad Up </option>
                                        <option value={'D-padDown'}>D-pad Down </option>
                                    </select>
                                    <button className="btn btn-purple my-3" type='button' onClick={saveMappingForm}>Save</button>
                                    {fixedMappingErrorMessage && (<p className='formErrors'>{fixedMappingErrorMessage}</p>)}
                                </>
                                }
                            </div>
                            )}
                            <hr/>
                            {(userData.price !== 0) && (<h4 className="product-price mb-3">₹ <span>
                                {userData.price}</span>
                            </h4>)}
                            {/*<p className="mrpPrice">MRP <span className="strike"> ₹3500 </span></p>*/}
                            <button className="btn btn-purple mb-3" type='button' disabled={disableBuyButton} onClick={() => {buyNow()} }>Buy Now</button>

                            {buyNowValidation.controller_type && <p className='formErrors'>{buyNowValidation.controller_type}</p>}
                            {buyNowValidation.design_id && <p className='formErrors'>{buyNowValidation.design_id}</p>}
                            {buyNowValidation.version_id && <p className='formErrors'>{buyNowValidation.version_id}</p>}
                            {buyNowValidation.version_type && <p className='formErrors'>{buyNowValidation.version_type}</p>}
                        </div> 
                    </div>
                </div>  
                <div className="container mb-4">
                    <hr className="my-4"/>
                        
                    <div>
                        {/* for send in  */}
                        {!isRepair ? (<><p>Whether you have a cherished controller that holds sentimental value or you've recently invested in a new one, our "Send In Your Controller" service offers you the perfect solution. Instead of letting your old controller gather dust or feeling regret for purchasing a new one, our service allows you to breathe new life into your existing controller.
                        </p>
                        <p>So, don't let your old controller go to waste. Instead, give your existing controller a new life with our "Send In Your Controller" service. Unleash your creativity, and enjoy a gaming experience that is uniquely yours.</p>
                        <p>Join countless satisfied gamers who have entrusted us with their gaming controllers. Experience the convenience of having all your controller needs met under one roof. Whether you need new controller, repairs, customizations, or our signature "Send In Your Controller" service, we're here to deliver outstanding results that elevate your gaming to new heights.</p>

                        <div id="servicesTerms">
                            <div className="card">
                                <div className="card-header" data-bs-toggle="collapse" href="#one">                                    
                                    <h5 className='mb-0'><strong>SEND IN INSTRUCTIONS</strong></h5>
                                </div>
                                <div id="one" className="collapse show" data-bs-parent="#servicesTerms">
                                    <div className="card-body">
                                        <ol>
                                            <li>Select the customisation you desire from the available options and Complete your purchase.</li>
                                            <li>Print your invoice or write your order number on a note. (You will be including this in your box so when we receive your controller in the box, we will know it belongs to you.)</li>
                                            <li>Box up your existing controller. Bonus points for extra padding.
                                                <br/>
                                                <strong>Ship the Controller to:</strong><br/>
                                                <strong>Address:</strong> A/6, Manak vihar, near ashok nagar, new delhi.110018.India<br/>
                                                <strong>Phone:</strong> +91-9625554048<br/>
                                                <strong>Email:</strong> support@sagecontrollers.com<br/>                                               
                                            </li>
                                            <li>After you ship the controller, email/whatsapp the tracking number and your order number to support@sagecontrollers.com/+91-9625554048</li>
                                            <li>Once we receive your controller we will notify you confirming we have received it.</li>
                                            <li>Processing begins after we receive the controller and inspect it. We will begin building your order soon after. (Build times are the same for send in orders as new orders)</li>
                                            <li>Once your order is complete, we will ship it back to you and update your account with tracking information.</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header collapsed" data-bs-toggle="collapse" href="#two">
                                    <h5 className='mb-0'><strong>TERMS & CONDITIONS</strong></h5>
                                </div>
                                <div id="two" className="collapse" data-bs-parent="#servicesTerms">
                                    <div className="card-body">
                                        <p>Send in controllers are not eligible for a refund, but are covered under 90 days 'limited' warranty on customisation.</p>
                                        <p>Controllers sent to us must be in a 100% working condition and be cleaned before sending; otherwise, the controller will be subject to additional charges.</p>
                                        <p>Any damaged, excessively dirty, or very old controllers will be refused for service and returned at the customer's expense.</p>
                                        <p>We will not be responsible for controllers received without any order information</p>
                                        <p>We will not be responsible for lost or stolen shipments. We suggest that you properly seal the box with a bunch of tapes and use reputable shipping companies like DTDC, Blue Dart, etc. Most importantly, make sure to obtain a tracking number when shipping items to us.</p> 
                                    </div>
                                </div>
                            </div>
                        </div>
                        </>) : (<><p>In addition to our "Send In Your Controller" service, we also provide comprehensive repair solutions for your gaming gear. With our combined repair and customization services, you can enjoy a fully functional and personalized gaming controller tailored to your specific needs and preferences. </p>
                        <p>When it comes to repairs, our team of skilled technicians is well-equipped to handle a wide range of issues. Whether you're experiencing sticky buttons, or joystick drift, we have the expertise to diagnose and fix the problem efficiently. Rest assured, we use very high-quality replacement parts to ensure optimal performance and durability. So, don't let your old controller go to waste, and don't regret your recent purchase. Instead, give your existing controller a new lease on life with our "Send In Your Controller" service.</p>
                        <p>Sending in your controller for Repair & customization is a breeze. Our customer-friendly process ensures that you have a smooth experience from start to finish. Just package your controller securely, send it to us, and leave the rest to our expert team.</p>
                        <p><strong>We also provide repair service for THIRD PARTY CUSTOM CONTROLLERS. please provide detailed description of the issues you are facing thorugh whatsapp on our support number.</strong></p>

                        <div id="servicesTerms">
                            <div className="card">
                                <div className="card-header" data-bs-toggle="collapse" href="#collapseOne">                                    
                                    <h5 className='mb-0'><strong>SEND IN INSTRUCTIONS</strong></h5>
                                </div>
                                <div id="collapseOne" className="collapse show" data-bs-parent="#servicesTerms">
                                    <div className="card-body">
                                        <ol>
                                            <li>Select the customisation you desire from the available options and Complete your purchase.Select the Customisation you desire from the available options, make sure to mention all the problem/issues you are facing with your controller in detail and Complete your purchase.</li>
                                            <li>Print your invoice or write your order number on a note. (You will be including this in your box so when we receive your controller in the box, we will know it belongs to you.)</li>
                                            <li>Box up your existing controller. Bonus points for extra padding.
                                                <br/>
                                                <strong>Ship the Controller to:</strong><br/>
                                                <strong>Address:</strong> A/6, Manak vihar, near ashok nagar, new delhi.110018.India<br/>
                                                <strong>Phone:</strong> +91-9625554048<br/>
                                                <strong>Email:</strong> support@sagecontrollers.com<br/>                                               
                                            </li>
                                            <li>After you ship the controller, email/whatsapp the tracking number and your order number to support@sagecontrollers.com/+91-9625554048</li>
                                            <li>Once we receive your controller we will notify you confirming we have received it.</li>
                                            <li>Processing begins after we receive the controller and inspect it. We will send you invoice for the repair charges. & begin building your order soon after confirmation. (Build times are the same for send in orders as new orders)</li>
                                            <li>Once your order is complete, we will ship it back to you and update your account with tracking information.</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header collapsed" data-bs-toggle="collapse" href="#collapseTwo">
                                    <h5 className='mb-0'><strong>TERMS & CONDITIONS</strong></h5>
                                </div>
                                <div id="collapseTwo" className="collapse" data-bs-parent="#servicesTerms">
                                    <div className="card-body">
                                        <p>Send in controllers are not eligible for a refund, but are covered under 90 days 'limited' warranty on customisation.Controllers sent to us must be cleaned before sending.</p>
                                        <p>Any physically damaged, excessively dirty, or very old controllers will be refused for service and returned at the customer's expense.</p>
                                        <p>We will not be responsible for controllers received without any order information</p>
                                        <p>Make sure to mention all issues with your controller; any discrepancy will be subject to higher charges.</p>
                                        <p>We will not be responsible for lost or stolen shipments. We suggest that you properly seal the box with a bunch of tapes and use reputable shipping companies like DTDC, Blue Dart, etc. Most importantly, make sure to obtain a tracking number when shipping items to us.</p> 
                                        <p>We offer a free 45 day limited warranty for all replaced and repaired products from the time it is delivered.</p>
                                        <p>Repairs are solely done by our company. We are not associated with any third party companies that offer repairs or extended warranties.Unauthorized alteration or repair of our customized hydro-dipped products will void the warranty and will no longer be eligible for our company repairs or refunds.</p>
                                        <p>Repairable issues: THUMBSTICK DRIFT, stuck/sticky buttons, buttons not registering, button flex, paddle system, clicky trigger/bumper system, spring malfunction, Front buttons malfunction.</p>
                                        <p>Water damage, Severe physical damage, battery damaged, problem with motherboard, bluetooth connectivity CANNOT be fixed.</p>
                                        <p>We also provide repair solutions for THIRD PARTY CUSTOM CONTROLLERS(IMPORTED). please provide detailed description of the issues you are facing thorugh whatsapp on our support number.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </>)}

                    </div>

                    <div className='d-flex flex-md-row flex-column justify-content-around send-in-controller text-center align-items-center'>
                        <div className='column'>
                            <img src={`${appConstant.CDN_URL}/assets/images/pack.png`} className='send-in-image' alt={'box-up'}/>
                            <h5 className='send-in-title'>BOX UP YOUR CONTROLLER</h5>
                        </div>
                        <div className='column'>
                            <img src={`${appConstant.CDN_URL}/assets/images/send.png`} className='send-in-image' alt={'ship-controller'}/>
                            <h5 className='send-in-title'>SHIP YOUR CONTROLLER TO US</h5>
                        </div>
                        <div className='column'>
                            <img src={`${appConstant.CDN_URL}/assets/images/recieve.png`} className='send-in-image' alt={'receive-custom'}/>
                            <h5 className='send-in-title'>RECEIVE YOUR NEW CUSTOM SAGE!</h5>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    );
  }
  
  export default Services;