import React, {useEffect, useState} from 'react';
import Header from "./shared/Header";
import Footer from "./shared/Footer";
import Banner from "./shared/Banner";
import LoadingSpinner from "./shared/LoadingSpinner";
import appConstant from './shared/constant/constant.json';
import axios from "axios";
import {useParams} from "react-router-dom";

function Checkout() {
    const props = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
    const [shippingData, setShippingData] = useState([]);
    const [cartItems, setCartItems] = React.useState([]);
    const [paymentError, setPaymentError] = React.useState('');
    const [summaryData, setSummaryData] = useState({
        mrp: 0,
        subTotal: 0,
        discount: 0,
        coupon: 0,
        total: 0
    });
  const [coupon, setCoupon] = useState({
      coupon: '',
      couponType: '',
      couponValue: '',
      showCoupon: false,
      message: ''
  });

  const [isCoupon, setIsCoupon] = useState(false);
  const [couponValidation, setCouponValidation] = useState({
    coupon: ""
  });
    useEffect(() => {
        if(props.type === '1'){
            cart();
            getShippingDetails();
        }else if(props.type === '2' || props.type === '3'){
            const serviceData = JSON.parse(localStorage.getItem('service'))
            setSummaryData({
                mrp: serviceData.price,
                subTotal: serviceData.price,
                discount: 0,
                coupon: '',
                total: serviceData.price
            })
        }else{
            window.location.href = '/';
        }
    }, []);
  function handleCouponChange(e) {
    const { name, value } = e.target;
    setCoupon({ ...coupon, [name]: value });
}
  const checkCouponValidation = () => {
    let errors = JSON.parse(JSON.stringify(couponValidation));

    if (!coupon.coupon) {
    errors.coupon = "Coupon is required";
    } else {
    errors.coupon = "";
    }
    setCouponValidation(errors);
    if(errors.coupon === ''){
      return true;
    }else{
      return false;
    }
};
const applyCoupon = (e) => {
  e.preventDefault();
  const isSuccess = checkCouponValidation();
  if(isSuccess) {
    setIsCoupon(false);
      checkCoupon();
  }else{
    setIsCoupon(true);
  }
}

const checkCoupon = () => {
    const couponData = new FormData();
    couponData.append('coupon', coupon.coupon);
    const params = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*"
    };
    axios.post(`${appConstant.API_URL}/products/coupon`, couponData, params)
        .then(res => {
            if (res?.data?.status === 200) {
                const data = {
                    coupon: res?.data?.data?.name,
                    couponType: res?.data?.data?.coupon_type,
                    couponValue: res?.data?.data?.coupon_value,
                    showCoupon: true,
                    message: ''
                }
                setCoupon(data)
                summary(cartItems, data);
                setIsCoupon(false);
            }else {
                const data = {
                    coupon: coupon.coupon,
                    couponType: '',
                    couponValue: '',
                    showCoupon: false,
                    message: res?.data?.message
                }
                setCoupon(data);
                summary(cartItems, data);
                setIsCoupon(true);
            }
        })
        .catch(error => {
        });
}

  const [inputValues, setInputValue] = useState({
      fname: "",
      lname: "",
      number: "",
      email: "",
      address: "",
      country: "India",
      state: "",
      city: "",
      zip: "",
      paymentMode: '',
      notes:''
  });
  const [validation, setValidation] = useState({
      fname: "",
      lname: "",
      number: "",
      email: "",
      address: "",
      country: "India",
      state: "",
      city: "",
      zip: "",
      paymentMode: ''
  });
  function handleChange(e) {
      const { name, value } = e.target;
      setInputValue({ ...inputValues, [name]: value });
      setValidation({ ...validation, [name]: '' });
  }
  const checkValidation = () => {
      let errors = JSON.parse(JSON.stringify(validation));
  
      //first validation
      const alphabetCond = /^[a-zA-Z ]*$/;
      if (!inputValues.fname) {
      errors.fname = "First name is required";
      } else if (!inputValues.fname.match(alphabetCond)) {
      errors.fname = "Please enter valid first name";
      } else {
      errors.fname = "";
      }
      //last validation
      if (!inputValues.lname) {
      errors.lname = "Last name is required";
      } else if (!inputValues.lname.match(alphabetCond)) {
      errors.lname = "Please enter valid last name";
      } else {
      errors.lname = "";
      }
      //number validation
      // const numberCond = /^[0-9\b]+$/;
      if (!inputValues.number) {
      errors.number = "Number is required";
      }
      // else if (!inputValues.number.match(numberCond)) {
      // errors.number = "Please enter valid number";
      // }
      else {
      errors.number = "";
      }
      // email validation
      const emailCond = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!inputValues.email) {
        errors.email = "Email is required";
      } else if (!inputValues.email.match(emailCond)) {
        errors.email = "Please enter valid email address";
      } else {
        errors.email = "";
      }
      //address validation
      if (!inputValues.address) {
      errors.address = "Address is required";
      }else {
      errors.address = "";
      }
      //country validation
      if (!inputValues.country) {
        errors.country = "Country is required";
        }else {
        errors.country = "";
      }
      //state validation
      if (!inputValues.state) {
        errors.state = "State is required";
        }else {
        errors.state = "";
      }
      //city validation
      if (!inputValues.city) {
        errors.city = "City is required";
        }else {
        errors.city = "";
      }
      //zip validation
      if (!inputValues.zip) {
        errors.zip = "Zip is required";
        }else {
        errors.zip = "";
      }
      if (!inputValues.paymentMode) {
          errors.paymentMode = "Please select one";
      }else {
          errors.paymentMode = "";
      }

      setValidation(errors);
      if(errors.fname === '' && errors.lname === '' && errors.number === '' && errors.email === '' && errors.address === '' && errors.country === '' && errors.state === ''&& errors.city === '' && errors.zip === '' && errors.paymentMode === ''){
        return true;
      }else{
        return false;
      }
  };
  const payment = (e) => {
      e.preventDefault();
      const isSuccess = checkValidation();
      if(isSuccess) {
         setIsLoading(true);
          const params = {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*'
          }
          const checkout = new FormData();
          checkout.append('cart', localStorage.getItem('cart') ?? '' );
          checkout.append('service', localStorage.getItem('service') ?? '' );
          checkout.append('type', props?.type ?? '1' );
          checkout.append('shipping_data', JSON.stringify(inputValues));
          checkout.append('summary', JSON.stringify(summaryData));
          checkout.append('coupon_data', isCoupon ? coupon.coupon : '');
          checkout.append('token', localStorage.getItem('token') ?? '');
          axios.post(`${appConstant.API_URL}/products/order_checkout`, checkout, params)
              .then(res => {
                  setIsLoading(false);
                  console.log(res);
                  if(res?.data?.status === 200){
                      localStorage.removeItem('cart');
                      localStorage.removeItem('service');
                      window.location.href= "/success";
                  }else if(res?.data?.status === 201){
                      const paymentUrl = res?.data?.data?.link
                      window.location.href= paymentUrl;
                  }else{
                      setPaymentError(res?.data?.message);
                  }
              })
              .catch(error => {
                 //  setIsLoadingButton(false);
              });
      }
  }

//  Add address 
    const [addressValues, setAddressValues] = useState({
      fname: "",
      lname: "",
      number: "",
      email: "",
      address: "",
      country: "India",
      state: "",
      city: "",
      zip: ""
  });
  const [addressValidation, setAddressValidation] = useState({
      fname: "",
      lname: "",
      number: "",
      email: "",
      address: "",
      country: "India",
      state: "",
      city: "",
      zip: ""
  });
  function handleAddressChange(e) {
      const { name, value } = e.target;
      setAddressValues({ ...addressValues, [name]: value });
  }
  const checkAddressValidation = () => {
      let errors = JSON.parse(JSON.stringify(addressValidation));
  
      //first validation
      const alphabetCond = /^[a-zA-Z ]*$/;
      if (!addressValues.fname) {
      errors.fname = "First name is required";
      } else if (!addressValues.fname.match(alphabetCond)) {
      errors.fname = "Please enter valid first name";
      } else {
      errors.fname = "";
      }
      //last validation
      if (!addressValues.lname) {
      errors.lname = "Last name is required";
      } else if (!addressValues.lname.match(alphabetCond)) {
      errors.lname = "Please enter valid last name";
      } else {
      errors.lname = "";
      }
      //number validation
      // const numberCond = /^[0-9\b]+$/;
      if (!addressValues.number) {
      errors.number = "Number is required";
      }
      // else if (!addressValues.number.match(numberCond)) {
      // errors.number = "Please enter valid number";
      // }
      else {
      errors.number = "";
      }
      // email validation
      const emailCond = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!addressValues.email) {
        errors.email = "Email is required";
      } else if (!addressValues.email.match(emailCond)) {
        errors.email = "Please enter valid email address";
      } else {
        errors.email = "";
      }
      //address validation
      if (!addressValues.address) {
      errors.address = "Address is required";
      }else {
      errors.address = "";
      }
      //country validation
      // if (!addressValues.country) {
      //   errors.country = "Country is required";
      //   }else {
      //   errors.country = "";
      // }
      //state validation
      if (!addressValues.state) {
        errors.state = "State is required";
        }else {
        errors.state = "";
      }
      //city validation
      if (!addressValues.city) {
        errors.city = "City is required";
        }else {
        errors.city = "";
      }
      //zip validation
      if (!addressValues.zip) {
        errors.zip = "Zip is required";
        }else {
        errors.zip = "";
      }

      setAddressValidation(errors);
      if(errors.fname === '' && errors.lname === '' && errors.number === '' && errors.email === '' && errors.address === '' && errors.state === ''&& errors.city === '' && errors.zip === ''){
        return true;
      }else{
        return false;
      }
  };
  const addAddress = () => {
      const isSuccess = checkAddressValidation();
    if(isSuccess) {
        setIsLoadingButton(true);
        const shippingDetailsData = new FormData();
        shippingDetailsData.append('token', localStorage.getItem('token') );
        shippingDetailsData.append('first_name', addressValues.fname );
        shippingDetailsData.append('last_name', addressValues.lname );
        shippingDetailsData.append('email', addressValues.email );
        shippingDetailsData.append('address', addressValues.address );
        shippingDetailsData.append('country', addressValues.country );
        shippingDetailsData.append('mobile_number', addressValues.number );
        shippingDetailsData.append('state', addressValues.state );
        shippingDetailsData.append('city', addressValues.city );
        shippingDetailsData.append('postal_code', addressValues.zip );
        const params = {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*"
        };
        axios.post(`${appConstant.API_URL}/user/update_address/shipping`, shippingDetailsData, params)
            .then(res => {
                setIsLoadingButton(false);
                if (res?.data?.status === 200) {
                    console.log(res?.data?.message);
                    const addData = {
                        fname: "",
                        lname: "",
                        number: "",
                        email: "",
                        address: "",
                        country: "",
                        state: "",
                        city: "",
                        zip: ""
                    }
                    setAddressValues(addData);
                    getShippingDetails();
                }
            })
            .catch(error => {
                setIsLoadingButton(false);
            });
    }
  }
    const cart = () => {
        const params = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
        const cartData = new FormData();
        cartData.append('cart', localStorage.getItem('cart') ?? '' );
        axios.post(`${appConstant.API_URL}/products/cart`, cartData, params)
            .then(res => {
                setIsLoading(false);
                if(res?.data?.status === 200){
                    if(res?.data?.data?.cart){
                        const cart =  JSON.parse(res?.data?.data?.cart);
                        localStorage.setItem('cart', res?.data?.data?.cart);
                        const cartData = [];
                        cart.map((result, i) => {
                            const item = {
                                cartData: result,
                                product: res?.data?.data?.items[i]
                            }
                            cartData.push(item);
                        });
                        setCartItems(cartData);
                        summary(cartData, coupon);
                        //setTotalPrice(res?.data?.total_price);
                    }else{
                        localStorage.removeItem('cart')
                    }

                }else{
                    setCartItems([]);
                }
            })
            .catch(error => {
                setIsLoadingButton(false);
            });
    }
    const summary = (cartItems, coupon) => {
        let subTotalCheck = 0;
        let totalDiscount = 0;
        let mrpPrice = 0;
        let grandTotal = 0;
        let couponTotal = 0;
        cartItems.filter((item) => item.cartData.display === true).map((res, i) => {
            if(res.cartData.isVersion && res.cartData.versionName !== res.product.version_name){
                const getVersonPrice = res.product.product_meta.filter((item) => item.version_title === res.cartData.versionName);
                const price = parseInt(getVersonPrice[0].version_price) * parseInt(res.cartData.quantity);
                const salePrice = (parseInt(getVersonPrice[0].version_sale_price) + (res.cartData.typeName === 'remap' ? parseInt(res.product.remap_price) : 0)) * parseInt(res.cartData.quantity);
                let discount = 0;
                if(res.cartData.frequentlyBuy && res.cartData.parentId !== '0'){
                    const percentage = (salePrice / 100) * 10;
                    // discount = percentage;
                    discount = Math.round(percentage);
                }
                mrpPrice = mrpPrice + price;
                subTotalCheck = subTotalCheck + salePrice;
                totalDiscount = totalDiscount + discount;
            }else{
                const price = parseInt(res.product.price) * parseInt(res.cartData.quantity);
                const salePrice = (parseInt(res.product.sale_price) + (res.cartData.typeName === 'remap' ? parseInt(res.product.remap_price) : 0)) * parseInt(res.cartData.quantity);
                let discount = 0;
                if(res.cartData.frequentlyBuy && res.cartData.parentId !== '0'){
                    const percentage = (salePrice / 100) * 10;
                    discount = Math.round(percentage);
                    // discount = percentage;
                }
                mrpPrice = mrpPrice + price;
                subTotalCheck = subTotalCheck + salePrice;
                totalDiscount = totalDiscount + discount;
            }
        });
        couponTotal = couponValue(subTotalCheck, coupon.coupon, coupon.showCoupon, coupon.couponType, coupon.couponValue);
        grandTotal = subTotalCheck - totalDiscount - couponTotal;
        const checkMRP = (Math.round(mrpPrice) < Math.round(subTotalCheck) ) ? Math.round(subTotalCheck) : Math.round(mrpPrice);
        setSummaryData({
            mrp: checkMRP,
            subTotal: Math.round(subTotalCheck),
            discount: totalDiscount,
            coupon: couponTotal,
            total: Math.round(grandTotal)
        })
    }
    const getShippingDetails = () => {
        setIsLoading(true);
        const shippingDetailsData = new FormData();
        shippingDetailsData.append('token', localStorage.getItem('token') );
        const params = {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*"
        };
        axios.post(`${appConstant.API_URL}/user/address/shipping`, shippingDetailsData, params)
            .then(res => {
                setIsLoading(false);
                if (res?.data?.status === 200) {
                    setShippingData(res?.data?.data);
                    if(res?.data?.data?.length > 0){
                        for(let i=0; i<res?.data?.data?.length; i++){
                            if(res?.data?.data[i]?.default_address === '1'){
                                const newData = {
                                    fname:  res?.data?.data[i]?.first_name,
                                    lname: res?.data?.data[i]?.last_name,
                                    number: res?.data?.data[i]?.mobile_number,
                                    email: res?.data?.data[i]?.email,
                                    address: res?.data?.data[i]?.address,
                                    country: res?.data?.data[i]?.country,
                                    state: res?.data?.data[i]?.state,
                                    city: res?.data?.data[i]?.city,
                                    zip: res?.data?.data[i]?.postal_code,
                                    company: res?.data[i]?.data?.company ? res?.data[i]?.data?.company : ''
                                }
                                setInputValue(newData);
                            }
                        }

                    }

                }else {

                }
            })
            .catch(error => {
                setIsLoading(false);
            });
    }
    const deleteCoupon = () =>{
      const data = {
          coupon: '',
          couponType: '',
          couponValue: '',
          showCoupon: false,
          message: ''
      }
        setCoupon(data);
        summary(cartItems, data);
    }

    const couponValue = (price, name, showCoupon, couponType, couponValue) =>{
      let coupon = 0;
      if(price && name && showCoupon && couponType && couponValue){
          if(couponType === 'flat'){
              coupon = parseInt(couponValue);
          }else{
              coupon = (parseInt(price) * parseInt(couponValue)) / 100;
          }
      }
      return coupon;
    }
    const makeThisAddress = (i) => {
        const newData = {
            fname:  shippingData[i]?.first_name,
            lname: shippingData[i]?.last_name,
            number: shippingData?.mobile_number,
            email: shippingData[i]?.email,
            address: shippingData[i]?.address,
            country: shippingData[i]?.country,
            state: shippingData[i]?.state,
            city: shippingData[i]?.city,
            zip: shippingData[i]?.postal_code,
            company: shippingData[i]?.company ? shippingData[i]?.company : ''
        }
        setInputValue(newData);
    }

  return (
    <>
      <Header />
      <Banner name="Checkout" parentSlug="/" parent="Home" />
      <div className="faqsSection py-5">
        <div className="container">
            {localStorage.getItem('token') ? '' : (<h4 className='h6 mb-4'>You are checking out as guest. Have Account? <button type="button" className="links ms-2" data-bs-toggle="modal" data-bs-target="#login"> Login</button></h4>)}
            <div className="row">
                <div className="col-lg-8 mb-lg-0 mb-4">
                    <div className='card'>
                        <div className='card-body'>
                            <div className='row'>
                                <div className='col-sm-6'>
                                <label className='cart-product-version mb-1'>First Name</label>
                                <input className="mb-2 w-100 py-1 px-2 form-control" type="text" placeholder='Enter First Name' name="fname" value={inputValues.fname} onChange={(e) => handleChange(e)}/>
                                {validation.fname && <p className='formErrors'>{validation.fname}</p>}
                                </div>
                                <div className='col-sm-6'>
                                <label className='cart-product-version mb-1'>Last Name</label>
                                <input className="mb-2 w-100 py-1 px-2 form-control" type="text" placeholder='Enter Last Name' name="lname" value={inputValues.lname} onChange={(e) => handleChange(e)}/>
                                {validation.lname && <p className='formErrors'>{validation.lname}</p>}
                                </div>
                                <div className='col-sm-6'>
                                <label className='cart-product-version mb-1'>Phone Number</label>
                                <input className="mb-2 w-100 py-1 px-2 form-control" type="text" placeholder='Enter Phone Number' name="number" value={inputValues.number} onChange={(e) => handleChange(e)}/>
                                    {validation.number && <p className='formErrors'>{validation.number}</p>}
                                </div>
                                <div className='col-sm-6'>
                                <label className='cart-product-version mb-1'>Email Id</label>
                                <input className="mb-2 w-100 py-1 px-2 form-control" type="text" placeholder='Enter Email Id' name="email" value={inputValues.email} onChange={(e) => handleChange(e)}/>
                                    {validation.email && <p className='formErrors'>{validation.email}</p>}
                                </div>
                                <div className='col-sm-6'>
                                <label className='cart-product-version mb-1'>Address</label>
                                <input className="mb-2 w-100 py-1 px-2 form-control" type="text" placeholder='Enter Address' name="address" value={inputValues.address} onChange={(e) => handleChange(e)}/>
                                    {validation.address && <p className='formErrors'>{validation.address}</p>}
                                </div>
                                {/* <div className='col-sm-6'>
                                <label className='cart-product-version mb-1'>Country</label>
                                <select className="mb-2 w-100 py-1 px-2 form-control" name="country" value={inputValues.country} onChange={(e) => handleChange(e)}>
                                    <option value="">Select Country</option>
                                    {appConstant.COUNTRY.length > 0  && appConstant.COUNTRY?.map((result, i) =>
                                      <option value={result} key={i}>{result}</option>
                                    )} 
                                </select>
                                {validation.country && <p className='formErrors'>{validation.country}</p>}
                                </div> */}
                                <div className='col-sm-6'>
                                <label className='cart-product-version mb-1'>State</label>
                                <select className="mb-2 w-100 py-1 px-2 form-control" name="state" value={inputValues.state} onChange={(e) => handleChange(e)}>
                                    <option value="">Select State</option>                           
                                    {appConstant.STATES.length > 0  && appConstant.STATES?.map((result, i) =>
                                      <option value={result} key={i}>{result}</option>
                                    )}                          
                                </select>
                                {validation.state && <p className='formErrors'>{validation.state}</p>}
                                </div>
                                <div className='col-sm-6'>
                                <label className='cart-product-version mb-1'>City</label>
                                <input className="mb-2 w-100 py-1 px-2 form-control" type="text" placeholder='Enter City' name="city" value={inputValues.city} onChange={(e) => handleChange(e)}/>
                                    {validation.city && <p className='formErrors'>{validation.city}</p>}
                                </div>
                                <div className='col-sm-6'>
                                <label className='cart-product-version mb-1'>Zip/Postal Code</label>
                                <input className="mb-2 w-100 py-1 px-2 form-control" type="text" placeholder='Enter Zip/Postal Code' name="zip" value={inputValues.zip} onChange={(e) => handleChange(e)}/>
                                    {validation.zip && <p className='formErrors'>{validation.zip}</p>}
                                </div>
                            </div>
                        </div>
                    </div>

                    {shippingData?.length > 0 && (<div className='card'>
                      <div className='card-body'>
                        <h4 className="h5 mb-4">Shipping Address</h4>
                        <button type="button" className='btn btn-purple mb-2' data-bs-toggle="modal" data-bs-target="#add-address">Add New Address</button>

                        <div className='row mt-3'>
                            {shippingData?.map((result, i) =>
                                (<div className='col-sm-6 mb-4' key={'address' + i}>
                                <div className='card add-address default'>
                                <div className='card-body position-relative p-4 pb-2'>
                                    <p className='address'>
                                        <div style={{textTransform : 'capitalize', fontWeight : '700'}}>{result?.first_name} {result?.last_name}</div>
                                        <span>{result?.address} {result?.city} {result?.state} {result?.postal_code}</span>
                                        <div><b>Email:</b> {result?.email}</div>
                                        <div><b>Phone:</b> {result?.mobile_number}</div>
                                    </p>
                                    <div className='d-flex justify-content-between flex-wrap'>
                                        <button type="button" className='btn btn-purple btn-sm mb-2' onClick={() => {makeThisAddress(i)}}>Use This Address</button>
                                    </div>
                                </div>
                                </div>
                            </div>)
                            )}
                        </div>
                      </div>
                    </div>)}
                </div>
    
                <div className="col-lg-4">
                    <div className="card mb-3">
                        <div className="card-header bg-white">
                            <h4 className="cart-product-name">Total</h4>
                        </div>
                        <div className="card-body">
                            <h4 className="cart-product-version d-flex justify-content-between w-100 mb-2"><span>MRP</span> <strong>&#x20B9; {summaryData.mrp} </strong></h4>
                            <h4 className="cart-product-version d-flex justify-content-between w-100 mb-2"><span>Subtotal</span> <strong>&#x20B9; {summaryData.subTotal} </strong></h4>
                            <h4 className="cart-product-version d-flex justify-content-between w-100 mb-2"><span>Discount</span> <strong>&#x20B9; {summaryData.discount}</strong></h4>
                            {coupon.showCoupon && (<h4 className="cart-product-version d-flex justify-content-between w-100 mb-2"><span>Coupon{coupon.couponType !== 'flat' ? '('+coupon.couponValue+'%)' : ''}</span> <strong>&#x20B9; {summaryData.coupon}</strong></h4>)}
                            <h4 className="cart-product-version d-flex justify-content-between w-100 mb-2"><span>Total</span> <strong>&#x20B9; {summaryData.total} </strong></h4>
                        </div>
                    </div>
                    <div className="card mb-3">
                       <div className="card-header bg-white">
                           <h4 className="cart-product-name">Add Notes</h4>
                       </div>
                       <div className="card-body">                           
                           <textarea className='py-1 px-2 w-100' placeholder='Please write notes for your order' name='notes' rows='2' onChange={(e) => handleChange(e)} value={inputValues.notes}></textarea>
                       </div>
                    </div>
                    {props.type === '1' && (<div className="card mb-3">
                       <div className="card-header bg-white">
                           <h4 className="cart-product-name">Apply Coupon</h4>
                       </div>
                       <div className="card-body">                           
                           {!coupon.showCoupon && <input type='text' name='coupon' className={isCoupon ? "coupon red mb-2 w-100 py-1 px-2" : "coupon mb-2 w-100 py-1 px-2"} value={coupon.coupon} onChange={(e) => handleCouponChange(e)}/>}

                           {couponValidation.coupon && <p className='formErrors'>{couponValidation.coupon}</p>}

                           {(!coupon.showCoupon && coupon.message) && (<p className='formErrors'>{coupon.message}</p>) }

                           {coupon.showCoupon && <div className='text-center'><span className='valid-coupon'>{coupon.coupon}</span><button className='remove-coupon' onClick={()=>{deleteCoupon()}}>&times;</button></div>}

                           {!coupon.showCoupon && <button  className="btn btn-purple btn-sm d-block" onClick={(e) => applyCoupon(e)}>Apply</button>}
                       </div>
                    </div>)}
                    <div className="card">
                       <div className="card-header bg-white">
                           <h4 className="cart-product-name">Payment Method</h4>
                       </div>
                       <div className="card-body">                           
                          <p><small>Please select a prefered payment method to use on this order.</small></p>

                          <label className="payment-label">COD
                            <input type="radio" name="paymentMode" value="cod" checked={inputValues.paymentMode === 'cod'} onChange={(e) => handleChange(e)}/>
                            <span className="checkmark"></span>
                          </label>
                          <label className="payment-label">Phone Pay
                            <input type="radio" name="paymentMode" value="phonePe" checked={inputValues.paymentMode === 'phonePe'} onChange={(e) => handleChange(e)}/>
                            <span className="checkmark"></span>
                          </label>
                           {validation.paymentMode && <p className='formErrors'>{validation.paymentMode}</p>}
                       </div>
                       
                       <div className="card-footer text-center bg-white p-2">
                            {isLoading ? <button type='submit' className="cart-trash filled">Confirm Order <i className="fa fa-arrow-right mx-1"></i><img className='ms-2' src={`${appConstant.CDN_URL}/assets/images/default.gif`} width={'16px'} height={'16px'} alt={'default'}/></button> :
                               <button type='submit' className="cart-trash filled" onClick={(e) => payment(e)}>Confirm Order <i className="fa fa-arrow-right mx-1"></i></button>
                            }
                           {paymentError && <p className='formErrors'>{paymentError}</p>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <Footer />

       {/* add-address popup  */}
       <div className="modal back-shadow" id="add-address">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <button type="button" className="close" data-bs-dismiss="modal">&times;</button>
                    <div className="modal-body p-4">
                    {isLoading ? <LoadingSpinner/> :
                        <><div className='row'>
                          <div className='col-sm-12'>
                            <label className='cart-product-version mb-1'>First Name</label>
                            <input className="mb-2 w-100 py-1 px-2 form-control" type="text" placeholder="First Name" name="fname" value={addressValues.fname} onChange={(e) => handleAddressChange(e)}/>
                            {addressValidation.fname && <p className='formErrors'>{addressValidation.fname}</p>}
                          </div>
                          <div className='col-sm-12'>
                            <label className='cart-product-version mb-1'>Last Name</label>
                            <input className="mb-2 w-100 py-1 px-2 form-control" type="text" placeholder="Last Name" name="lname" value={addressValues.lname} onChange={(e) => handleAddressChange(e)}/>
                            {addressValidation.lname && <p className='formErrors'>{addressValidation.lname}</p>}
                          </div>
                          <div className='col-sm-12'>
                            <label className='cart-product-version mb-1'>Mobile Number</label>
                            <input className="mb-2 w-100 py-1 px-2 form-control" type="text" placeholder="Mobile Number" name="number" value={addressValues.number} onChange={(e) => handleAddressChange(e)}/>
                            {addressValidation.number && <p className='formErrors'>{addressValidation.number}</p>}
                          </div>
                          <div className='col-sm-12'>
                            <label className='cart-product-version mb-1'>Email</label>
                            <input className="mb-2 w-100 py-1 px-2 form-control" type="text" placeholder="Email" name="email" value={addressValues.email} onChange={(e) => handleAddressChange(e)}/>
                            {addressValidation.email && <p className='formErrors'>{addressValidation.email}</p>}
                          </div>
                          <div className='col-sm-12'>
                            <label className='cart-product-version mb-1'>Address</label>
                            <input className="mb-2 w-100 py-1 px-2 form-control" type='text' name='address' placeholder='Address' value={addressValues.address} onChange={(e) => handleAddressChange(e)}/>
                            {addressValidation.address && <p className='formErrors'>{addressValidation.address}</p>}
                          </div>
                          <div className='col-sm-12'>
                            <label className='cart-product-version mb-1'>State</label>
                            <select className="mb-2 w-100 py-1 px-2 form-control" name="state" value={addressValues.state} onChange={(e) => handleAddressChange(e)}>
                                {appConstant.STATES.length > 0  && appConstant.STATES?.map((result, i) =>
                                    <option value={result} key={i}>{result}</option>
                                )}
                            </select>  
                            {addressValidation.state && <p className='formErrors'>{addressValidation.state}</p>}             
                          </div>
                          <div className='col-sm-12'>
                            <label className='cart-product-version mb-1'>City</label>
                            <input className="mb-2 w-100 py-1 px-2 form-control" type="text" placeholder='Enter City' name='city' value={addressValues.city} onChange={(e) => handleAddressChange(e)}/>  
                            {addressValidation.city && <p className='formErrors'>{addressValidation.city}</p>}            
                          </div>
                          <div className='col-sm-12'>
                            <label className='cart-product-version mb-1'>Zip/Postal Code</label>
                            <input className="mb-2 w-100 py-1 px-2 form-control" type="text" placeholder='Enter Zip/Postal Code' name='zip'  value={addressValues.zip} onChange={(e) => handleAddressChange(e)}/> 
                            {addressValidation.zip && <p className='formErrors'>{addressValidation.zip}</p>}             
                          </div>
                        </div>
                        {isLoadingButton ? <div className='text-center mt-3'><button type='submit' className="btn btn-purple">Add Address <img alt="loader" className='ms-2' src={`${appConstant.CDN_URL}/assets/images/default.gif`} width={'16px'} height={'16px'}/></button></div> :
                          <div className='text-center mt-3'><button type='button' className="btn btn-purple" onClick={() => {addAddress()}}>Add Address</button></div>
                        }</>
                    }
                    </div>
                </div>
            </div>
        </div>
    </>
  );
}

export default Checkout;
