import React, {useEffect, useState} from 'react';
import Header from "./shared/Header";
import Footer from "./shared/Footer";
import Contact from "./shared/Contact";
import ProductCard from "./shared/ProductCard";
import { Link } from "react-router-dom";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Faqs from "./shared/Faqs";
import axios from "axios";
import appConstant from "./shared/constant/constant.json";
import LoadingSpinner from './shared/LoadingSpinner.js';

function Home() {
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingTestimonial, setIsLoadingTestimonial] = useState(true);
    const [isLoadingCategory, setIsLoadingCategory] = useState(true);
    const [bannersItem, setBannersItem] = useState([]);
    const [testimonialItem, setTestimonialItem] = useState([]);
    const [topRated, setTopRated] = useState([]);
    const [categoryItem, setCategoryItem] = useState([]);

    useEffect(() => {
        banners();
        testimonial();
        getTopRated();
        categories();
    }, []);
    const params = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    }
    const testimonial = () => {
     axios.get(`${appConstant.API_URL}/c/testimonials`, params)
          .then(res => {
            setIsLoadingTestimonial(false);
            if(res?.data?.status === 200){
              setTestimonialItem(res?.data?.data);
            }else{
                setTestimonialItem([]);
            }
          })
          .catch(error => {
            setIsLoadingTestimonial(true);
          });
    }
    const getTopRated = () => {
        axios.get(`${appConstant.API_URL}/c/single_category/top_rated`, params)
            .then(res => {
                if(res?.data?.status === 200){
                    setTopRated(res?.data?.data);
                }else{
                    setTopRated([]);
                }
            })
            .catch(error => {
            });
    }
    const banners = () => {
      axios.get(`${appConstant.API_URL}/c/banners`, params)
          .then(res => {
            setIsLoading(false);
            if(res?.data?.status === 200){
              setBannersItem(res?.data?.data);
            }else{
                setBannersItem([]);
            }
          })
          .catch(error => {
            setIsLoading(true);
          });
    }
    const categories = () => {
      axios.get(`${appConstant.API_URL}/c/category/0/1`, params)
      .then(res => {
        setIsLoadingCategory(false);
        if(res?.data?.status === 200){
          setCategoryItem(res?.data?.data);
        }else{
            setCategoryItem([]);
        }
      })
      .catch(error => {
        setIsLoadingCategory(true);
      });
    }

    const banner = {
        superLargeDesktop: {
          breakpoint: { max: 4000, min: 3000 },
          items: 1
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 1
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 1
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
    };
    return (
      <>
        <Header />
        {/* hero */}
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
                <div className="hero">
                  <Carousel
                    responsive={banner}
                    showDots={false}
                    autoPlay={true}
                    autoPlaySpeed={3000}
                    infinite
                    arrows={true}
                  >
                    {bannersItem?.length > 0  && bannersItem?.map((result, i) =>
                    <div className="heroCarousel" key={'banner' + i}>
                        <img className="heroImages" src={result?.banner_image} alt={result?.title} />
                    </div>
                    )}
                  </Carousel>
                </div>
          </>
        )}
        {/* cards */}
        <div className="background" id="cards_landscape_wrap-2">
          <div className="container py-5 text-center">
            <h4 className="h1 mb-0">Personalized Your Controller</h4>
            <h4 className="h5">Enhance your gaming experience</h4>
            <div className="row">
            {isLoadingCategory ? (<LoadingSpinner />) : (<> 
              {categoryItem?.length > 0  && categoryItem?.map((result, i) =>
              <div className="col-12 col-sm-6 col-md-4" key={'category' + i}>
                <Link to={"/category/" + result?.slug}>
                  <div className="card-flyer">
                    <div className="text-box">
                      <div className="image-box">
                        <img src={`${appConstant.IMAGE_URL}/category/` + result?.category_image} alt={result?.category} />
                      </div>
                      <div className="text-container">
                        <h6>{result?.category}</h6>
                        <p>
                        {result?.description}
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
                )}
              </>)}
            </div>
          </div>
        </div>

        {/* product Gallery */}
        <section className="product-section pb-4">
          <div className="container text-center py-4">
            <h4 className="h1 mb-0">COMMUNITY TOP RATED</h4>
            <h4 className="h5">Check what other players like</h4>
            <div className="shell">
              <div className="container">
                <div className="row">
                    {topRated?.products?.length > 0  && topRated?.products?.map((result, i) =>
                  <div className="col-lg-4 col-sm-6 col-12" key={'topRated' + i}>
                    <ProductCard
                      data={result}
                    />
                  </div>
                )}
                </div>
              </div>
            </div>
            <div className="mb-4 py-4">
              <Link className="button" to="/shop">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                View All
              </Link>
            </div>
          </div>
        </section>

        {/* parallex */}
        <div className="parallex">
          {/* Second Parallax */}
          <div className="second-parallax"></div>

          {/* Second Text Group */}
          <div className="row group align-items-center text-black bg-white">
            <div className="col-lg-6 group-text text-black order-lg-1 order-2">
              <img
                className="img-responsive w-100"
                src={`${appConstant.CDN_URL}/assets/images/he.png`}
                alt="hall effect"
              />
            </div>
            <div className="col-lg-6 group-text text-black text-lg-end text-start order-lg-2 order-1">
              <h4 className="h1 mb-0">HALL EFFECT SENSORS</h4>
              <h4 className="h5">Hall effect thumbsticks are more durable than standard analog sticks because they use a magnetic sensors. Upgrade to Hall Effect Thumbsticks for enhanced durability and precision.</h4>
              <p className="lead">Contact customer support for price and installation options.</p>
            </div>
          </div>
          {/* Second Text Group END */}
          {/* First Parallax */}
          <div className="first-parallax"></div>

          {/* First Text Group */}
          {/* <div className="row group align-items-center">
            <div className="col-lg-6 group-text">
              <h4 className="h1 mb-0">GEAR UP</h4>
              <h4 className="h5">Check our accessories</h4>
              <p className="lead">
              Playstation 5, 4 and Xbox accessories including Triggerstop, Faceplate and more
              </p>
            </div>
            <div className="col-lg-6 group-text">
              <img
                className="img-responsive w-100"
                src={`${appConstant.CDN_URL}/assets/images/gare.png`}
                alt="gare"
              />
            </div>
          </div> */}
          {/* First Text Group END */}
        </div>

        {/* faqs */}
        <div className="faqsSection py-4">
          <div className="container py-4">
            <div className="row">
              <div className="col-lg-6">
                <h4 className="h1 mb-2 text-start">FAQs</h4>
                <h4 className="h6 text-start mb-4">
                  See below our frequently asked question, for more FAQ, please
                  click this{" "}
                  <Link className="links" to="/faqs">
                    Link
                  </Link>
                </h4>
                <h4 className="h6 text-start mb-5">
                  Our customer support is available Monday to Friday:
                  9:00am-5:00pm.
                  <br />
                  Average answer time: 24h
                </h4>
                <div className="text-start mb-4">
                  <Link to="/contact-us" className="btn-login">
                    CONTACT US
                  </Link>
                </div>
              </div>
              <div className="col-lg-6">
                <Faqs page='1' />
              </div>
            </div>
          </div>
        </div>

        {/* testimonials */}
        <div className="testimonials">
          <div className="container text-center text-white py-5">
            <h4 className="h1 mb-0">TESTIMONIALS</h4>
            <h4 className="h5">What our client says</h4>
          </div>
          {isLoadingTestimonial ? (
          <LoadingSpinner />
        ) : (
          <>             
          <Carousel
            responsive={banner}
            showDots={false}
            autoPlay={true}
            autoPlaySpeed={3000}
            infinite
            arrows={true}
            removeArrowOnDeviceType={["mobile", "tablet"]}
          >
            {testimonialItem?.length > 0  && testimonialItem?.map((result, i) =>
            <div className="testimonial-content" key={"testimonial" + i}>
              <img
                className="test-img"
                src={result?.image}
                alt="banner"
              />
              <h5 className="test-name">{result?.name}</h5>
              <p className="test-content">
              {result?.description}
              </p>
            </div>
            )}
          </Carousel>
          </>
        )}
        </div>
        
        <Contact />
        <Footer />
      </>
    );
  }
  
  export default Home;
  