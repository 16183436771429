import Header from "./shared/Header";
import Footer from "./shared/Footer";
import Contact from "./shared/Contact";
import Banner from "./shared/Banner";

function ContactUs() {
    return (
        <>
            <Header/>
            <Banner name='Contact Us' parentSlug='/' parent='Home'/>
            <Contact/>
            <Footer/>
        </>
    );
  }
  
  export default ContactUs;