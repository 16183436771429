import React, {useEffect, useState} from 'react';
import Header from "./shared/Header";
import Footer from "./shared/Footer";
import {Link, useParams, useSearchParams} from "react-router-dom";
import Faqs from "./shared/Faqs";
import axios from "axios";
import appConstant from "./shared/constant/constant.json";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import ProductCard from "./shared/ProductCard";
import LoadingSpinner from "./shared/LoadingSpinner";

function SingleProduct() {
    const [isLoading, setIsLoading] = useState(true);
    const props = useParams();
    const [product, setProduct] = useState({});
    const [frequentlyBought, setFrequentlyBought] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);
    const [sliderData, setSliderData] = useState([]);

    const related = {
        superLargeDesktop: {
          breakpoint: { max: 4000, min: 3000 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3
        },
        tablet: {
          breakpoint: { max: 1023, min: 576 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 575, min: 0 },
          items: 1
        }
    };

    const [changeableItem, setChangeableItem] = useState({
        isVersion: false,
        versionName: '',
        price: '0',
        salePrice: '0',
        description: '',
        productType: 'accessories',
        typeName: '',
        disableCartButton: false
    });
    let [searchParams] = useSearchParams();
    const [userControllerDetails, setUserControllerDetails] = useState({
        type: 'basic',
        index: '0'
    })
    useEffect(() => {
        if(searchParams?.get('type') && searchParams?.get('index')){
            getProductData(searchParams?.get('type'), searchParams?.get('index'));
            setUserControllerDetails({
                type: searchParams?.get('type'),
                index: searchParams?.get('index')
            })
        }else{
            getProductData();
            setUserControllerDetails({
                type: 'basic',
                index: '0'
            })
        }

    }, []);
    const [fixedMappingErrorMessage, setFixedMappingErrorMessage] = useState('')
    const [inputPaddle, setInputPaddle] = useState({
        upperLeft : "",
        upperRight : "",
        lowerLeft : "",
        lowerRight : "",
    });
    const handlePaddle = (e) => {
        setFixedMappingErrorMessage('');
        const {name, value} = e.target;
        setInputPaddle({...inputPaddle, [name]: value});
    }

    const [mappingFormFilled, setMappingFormFilled] = useState(false);
    const saveMappingForm = () => {
        if(inputPaddle.lowerLeft === '' || inputPaddle.lowerRight === '' || inputPaddle.upperLeft === '' || inputPaddle.upperRight === ''){
            setFixedMappingErrorMessage('All fields are mandatory.');
            return false;
        }else{
            setMappingFormFilled(true);
            setChangeableItem({...changeableItem, ...{disableCartButton: false}})
        }
    }
    const showMappingForm = () => {
        setMappingFormFilled(false);
    }
    const refreshHandleVersion = (type = '', i = '0') => {
        setUserControllerDetails({
            type: type,
            index: i
        })
        handleVersion(product, type, i)
        window.history.pushState(null, '', '/product/' + props.slug + '/?type=' + type + '&index=' + i);
    }
    
    const handleVersion = (product, type = '', i= '0') => {
        let data = {...changeableItem};
        if(type === 'version'){
            const modifyData = {
                isVersion: true,
                versionName: product?.product_meta[i]?.version_title ?? '',
                price: product?.product_meta[i]?.version_price,
                salePrice: product?.product_meta[i]?.version_sale_price,
                description: product?.product_meta[i]?.version_description,
                productType: product?.product_type,
                disableCartButton: product.product_type === 'controller'
            }
            setChangeableItem({...data, ...modifyData});
            setActiveIndex(parseInt(i)+1)
        }else{
            const modifyData = {
                isVersion: false,
                versionName: product?.version_name ?? '',
                price: product?.price,
                salePrice: product?.sale_price,
                description: product?.short_description,
                productType: product?.product_type,
                disableCartButton: product.product_type === 'controller'
            }
            setChangeableItem({...data, ...modifyData});
            setActiveIndex(0)
        }
    }
    const handleMapping = (e) => {
        const data = {...changeableItem};
        if((inputPaddle.lowerLeft === '' || inputPaddle.upperLeft === '' || inputPaddle.lowerRight === '' || inputPaddle.upperRight === '') && e.target.value === 'fixed-mapping'){
            data.typeName =  e.target.value;
            data.disableCartButton = true;
            setChangeableItem(data)
        }else{
            data.typeName =  e.target.value;
            data.disableCartButton = false;
            setChangeableItem(data)
        }
    }

    const [resultMessage, setResultMessage] = useState('');
    const [inputValues, setInputValues] = useState({
        productQty : "1",
    });
    const handleChange = (e) => {
        const {name, value} = e.target;
        setInputValues({...inputValues, [name]: value});
    }
    const handleFrequentlyChange = (e, i) => {
        const data = [...frequentlyBought];
        data[i].checked = e.target.checked;
        setFrequentlyBought(data);
    }
    const [validation, setValidation] = useState({
        productQty : "",
    });
    const checkValidation = () => {
        let errors = JSON.parse(JSON.stringify(validation));
        //productQty validation
        if (!inputValues.productQty) {
          errors.productQty = "Quantity is required";
        }else {
          errors.productQty = "";
        }
        setValidation(errors);

        if(errors.productQty === ''){
          return true;
        }else{
          return false;
        }
    };
    const addQty = (e, isFrequentlyBuy = false) => {
        e.preventDefault();
        const isSuccess = checkValidation();
        if(isSuccess) {
            const frequentlyBuyFilter = frequentlyBought.filter((item) => item.checked === true);
            const CartData = {
                productId: product?.id,
                quantity: inputValues.productQty,
                productType: changeableItem.productType,
                isVersion: changeableItem.isVersion,
                versionName: changeableItem?.versionName ? changeableItem?.versionName : '',
                typeName: changeableItem.typeName ?? '',
                fixedMapping: inputPaddle ?? '',
                frequentlyBuy: isFrequentlyBuy,
                display: true,
                parentId: '0',
                discount: isFrequentlyBuy && (frequentlyBuyFilter.length === product?.frequently_bought.length)
            }
            const getLocalStorageCart = localStorage.getItem('cart');
            if(getLocalStorageCart){
                const parseData = JSON.parse(getLocalStorageCart);
                parseData.push(CartData);
                if(isFrequentlyBuy === true){
                    frequentlyBuyFilter.map((result)=> {
                        const CartData = {
                            productId: result?.id,
                            quantity: inputValues.productQty,
                            productType: product.productType,
                            isVersion: false,
                            versionName: '',
                            typeName: '',
                            fixedMapping: '',
                            frequentlyBuy: isFrequentlyBuy,
                            display: true,
                            parentId: product?.id,
                            discount: isFrequentlyBuy && (frequentlyBuyFilter.length === product?.frequently_bought.length)
                        }
                        parseData.push(CartData);
                    });
                }
                localStorage.setItem('cart', JSON.stringify(parseData));
            }else{
                const addInCart = [];
                addInCart.push(CartData);
                if(isFrequentlyBuy === true){
                    frequentlyBuyFilter.map((result)=> {
                        const CartData = {
                            productId: result?.id,
                            quantity: inputValues.productQty,
                            productType: product.productType,
                            isVersion: false,
                            versionName: '',
                            typeName: '',
                            fixedMapping: '',
                            frequentlyBuy: isFrequentlyBuy,
                            display: true,
                            parentId: product?.id,
                            discount: isFrequentlyBuy && (frequentlyBuyFilter.length === product?.frequently_bought.length)
                        }
                        addInCart.push(CartData);
                    });
                }
                localStorage.setItem('cart', JSON.stringify(addInCart));
            }
            setResultMessage('Successfully added.')
            setResultMessageBlank(true)
        }
    }


    const params = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    }
    const getProductData = (version= '', index= 0) => {
        axios.get(`${appConstant.API_URL}/products/single/${props.slug}`, params)
            .then(res => {
                setIsLoading(false);
                if(res?.data?.status === "200"){
                    let mockSliderData = [];
                    setProduct(res?.data?.data);
                    const images = [];
                    images.push({name: res?.data?.data?.featured_image, active: true});
                    if(res?.data?.data?.image_1) { images.push({name: res?.data?.data?.image_1, active: false})}
                    if(res?.data?.data?.image_2) { images.push({name: res?.data?.data?.image_2, active: false})}
                    if(res?.data?.data?.image_3) { images.push({name: res?.data?.data?.image_3, active: false})}
                    mockSliderData.push(images);
                    handleVersion(res?.data?.data, version, index)
                    if(res?.data?.data?.is_version === '1' && res?.data?.data?.product_meta.length > 0){
                        for(let item of res?.data?.data?.product_meta){
                            let data = [];
                            if(item?.version_image1) { data.push({name: item?.version_image1, active: false})}
                            if(item?.version_image2) { data.push({name: item?.version_image2, active: false})}
                            if(item?.version_image3) { data.push({name: item?.version_image3, active: false})}
                            mockSliderData.push([...images, ...data]);
                        }
                    }
                    console.log(mockSliderData);
                    setSliderData(mockSliderData);
                    if(res?.data?.data?.frequently_bought.length > 0){
                        const fB = [];
                        res?.data?.data?.frequently_bought.map((result, i)=>{
                            const item = {
                                title: result?.title,
                                id: result?.id,
                                image: result?.featured_image,
                                salePrice: result?.sale_price,
                                checked: true
                            }
                            fB.push(item);
                        });
                        setFrequentlyBought(fB);
                    }
                }else{
                    setProduct({});
                }
            })
            .catch(error => {
                setIsLoading(false);
            });
    }
    const getPercentage = (regularPrice, sale_price) => {
        const mrp = parseFloat(regularPrice);
        const salePrice = parseFloat(sale_price);
        if (!isNaN(mrp) && !isNaN(salePrice) && salePrice < mrp) {
            const discount = mrp - salePrice;
            const discountPercentage = ((discount / mrp) * 100).toFixed(2);
            return discountPercentage;
        } else {
            return 0;
        }
    }
    const addition = (val1, val2) => {
        return parseInt(val1) + parseInt(val2);
    }
    const frequentlyTotal = () => {
        const productPrice = (changeableItem.productType === 'controller' && changeableItem.typeName === 'remap') ? addition(changeableItem.salePrice, product?.remap_price) : changeableItem.salePrice;
        const filterData = frequentlyBought.filter((item) => item.checked === true);
        const sum = filterData.length > 0 ? filterData.reduce((accumulator, currentValue) => accumulator + parseInt(currentValue.salePrice), 0) : 0;
        return parseInt(productPrice) + sum;
    }
    const showProductImages = (gallery, index) => {
        return (
            <div id={"singleProduct" + index} key={'silder'+ index} data-bs-interval="false" className={"carousel slide singleProduct"} data-bs-ride="carousel" style={{display: activeIndex === index ? 'block' : 'none'}}>
                <div className="row">
                    <div className="col-sm-1 p-0 order-2 order-sm-1">
                        <ul className="carousel-indicators d-sm-flex d-none">
                            {gallery?.length > 0  && gallery?.map((result, i) =>
                                <li data-bs-target={"#singleProduct" + index} data-bs-slide-to={i} key={'thumb' + i} className={i=== 0 ? "active" : ''}>
                                    <img src={`${appConstant.IMAGE_URL}/products/` + result.name} alt={product.title + i} className="thumb"/>
                                </li>
                            )}
                        </ul>
                    </div>
                    <div className="col-sm-11 order-1 order-sm-2">
                        <div className="position-relative">
                            <div className="carousel-inner">
                                {gallery?.length > 0  && gallery?.map((result, i) =>
                                    <div key={'productImage' + i} className={i === 0 ? "carousel-item active" : 'carousel-item'}>
                                        <img src={`${appConstant.IMAGE_URL}/products/` + result.name} alt={product.title + i + '_image'} className="carousel-image"/>
                                    </div>
                                )}
                            </div>
                            <button className="carousel-control-prev" type="button" data-bs-target={"#singleProduct" + index} data-bs-slide="prev">
                                <span className="carousel-control-prev-icon"></span>
                            </button>
                            <button className="carousel-control-next" type="button" data-bs-target={"#singleProduct" + index} data-bs-slide="next">
                                <span className="carousel-control-next-icon"></span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const setResultMessageBlank = (type) => {
        if(type){
            const timer = setTimeout(() => {
                setResultMessage('')
            }, 3000);
            return () => clearTimeout(timer);
        }
    }
    return (
        <>
            <Header/>
            <div className="short-banner"></div>
            {isLoading ? <LoadingSpinner/> :
            <div className="single-product background">
                    <div className="row m-0 p-0">
                        <div className="col-lg-7  mb-4">
                            {sliderData.map((item, index)=>
                                showProductImages(item, index)
                            )}
                        </div>
                        <div className="col-lg-5">
                            <div className="bg-white border border-1 p-4">
                                <h4 className="h4 mb-3"><strong>{product.title}</strong></h4>
                                {/*<p className="mb-3">{product.short_description}</p>*/}

                                <div className="mb-3 d-flex">
                                    {product?.is_version === '1' ? (<div className="radio-option">
                                        <input type='radio' name='version' value={product.version_name} checked={userControllerDetails.type === 'basic' && userControllerDetails.index === '0'} onChange={() => refreshHandleVersion('basic', '0')}/>
                                        <label id="mansih">{product.version_name}</label>
                                    </div>) : ''}
                                    {product?.product_meta?.length > 0  && product?.product_meta?.map((result, i) =>
                                    <div className="radio-option" key={'version' + i}>
                                        <input type='radio' name='version' value={result?.version_title} checked={userControllerDetails.type === 'version' && userControllerDetails.index === i.toString()} onChange={() => refreshHandleVersion('version', i.toString())} />
                                        <label id="goyal">{result?.version_title}</label>
                                    </div>
                                    )}
                                </div>
                                {changeableItem.description ? <>
                                    <div dangerouslySetInnerHTML={{__html: changeableItem.description}}></div>
                                 </> : ''}
                                {product?.product_type === 'controller' && (<>{changeableItem.typeName === '' ? (<div style={{color:'red'}}>Please select any one.</div>) : ''}<div className="mb-3 d-flex">
                                    <div className="radio-option">
                                        <input type='radio' name='mapping' value='fixed-mapping' checked={changeableItem.typeName === 'fixed-mapping'} onChange={(e) => handleMapping(e)}/>
                                        <label>Fixed Mapping</label>
                                    </div>
                                    <div className="radio-option">
                                        <input type='radio' checked={changeableItem.typeName === 'remap'} name='mapping' value='remap' onChange={(e) => handleMapping(e)}/>
                                        <label>Remap Chip <span className="tooltiptext">+ &#x20B9; {product?.remap_price}</span></label>
                                    </div>
                                </div></>)}
                                {changeableItem.typeName === 'fixed-mapping' ? <>
                                <div className="fixed-mapping-form">                                                                        
                                    { mappingFormFilled ? 
                                    <>
                                        <div>
                                            <h5 className='h5 mb-3'><strong>Selected Mapping</strong></h5>
                                            <ul className='mb-3'>
                                                <li><h6><strong>Upper left hand paddle: </strong>{inputPaddle.upperLeft}</h6></li>
                                                <li><h6><strong>Upper right hand paddle: </strong>{inputPaddle.upperRight}</h6></li>
                                                <li><h6><strong>Lower left hand paddle: </strong>{inputPaddle.lowerLeft}</h6></li>
                                                <li><h6><strong>Lower right hand paddle: </strong>{inputPaddle.lowerRight}</h6></li>
                                            </ul>
                                            <button className="btn btn-purple" type='button' onClick={showMappingForm}>Edit Mapping</button>
                                        </div>
                                    </>
                                    : 
                                    
                                    <>
                                        <div className='fixed-map-image'>
                                            <img className="basic-image" alt='basic' src={`${appConstant.CDN_URL}/assets/images/fixed-mapping.png`} />
                                            {/* Upper left hand paddle  */}
                                            <h5 className='upper-left'>{inputPaddle.upperLeft}</h5>
                                            {/* Lower left hand paddle  */}
                                            <h5 className='lower-left'>{inputPaddle.lowerLeft}</h5>
                                            {/* Upper right hand paddle  */}
                                            <h5 className='upper-right'>{inputPaddle.upperRight}</h5>
                                            {/* Lower right hand paddle  */}
                                            <h5 className='lower-right'>{inputPaddle.lowerRight}</h5>
                                        </div>
                                        <p className="mb-3">Personalize button assignments for each of the 4 paddles with our Fixed Mapping Options</p>
                                        <strong className="d-block mb-1">Upper left hand paddle </strong>
                                        <select className="form-control px-2 py-1 w-100 mb-2" name='upperLeft' value={inputPaddle.upperLeft} onChange={(e) => handlePaddle(e)}>
                                            <option value={''}>--Select--</option>
                                            <option value={'CrossButton(X)'}>Cross Button (X)</option>
                                            <option value={'CircleButton(O)'}>Circle Button (O)</option>
                                            <option value={'SquareButton'}>Square Button </option>
                                            <option value={'TriangleButton'}>Triangle Button </option>
                                            <option value={'L3Button'}>L3 Button </option>
                                            <option value={'R3Button'}>R3 Button </option>
                                            <option value={'OptionButton'}>Option Button </option>
                                            <option value={'ShareButton'}>Share Button </option>
                                            <option value={'D-padLeft'}>D-pad Left </option>
                                            <option value={'D-padRight'}>D-pad Right </option>
                                            <option value={'D-padUp'}>D-pad Up </option>
                                            <option value={'D-padDown'}>D-pad Down </option>
                                        </select>
                                        <strong className="d-block mb-1">Upper right hand paddle </strong>
                                        <select className="form-control px-2 py-1 w-100 mb-2" name='upperRight' value={inputPaddle.upperRight} onChange={(e) => handlePaddle(e)}>
                                            <option value={''}>--Select--</option>
                                            <option value={'CrossButton(X)'}>Cross Button (X)</option>
                                            <option value={'CircleButton(O)'}>Circle Button (O)</option>
                                            <option value={'SquareButton'}>Square Button </option>
                                            <option value={'TriangleButton'}>Triangle Button </option>
                                            <option value={'L3Button'}>L3 Button </option>
                                            <option value={'R3Button'}>R3 Button </option>
                                            <option value={'OptionButton'}>Option Button </option>
                                            <option value={'ShareButton'}>Share Button </option>
                                            <option value={'D-padLeft'}>D-pad Left </option>
                                            <option value={'D-padRight'}>D-pad Right </option>
                                            <option value={'D-padUp'}>D-pad Up </option>
                                            <option value={'D-padDown'}>D-pad Down </option>
                                        </select>
                                        <strong className="d-block mb-1">Lower left hand paddle </strong>
                                        <select className="form-control px-2 py-1 w-100 mb-2" name='lowerLeft' value={inputPaddle.lowerLeft} onChange={(e) => handlePaddle(e)}>
                                            <option value={''}>--Select--</option>
                                            <option value={'CrossButton(X)'}>Cross Button (X)</option>
                                            <option value={'CircleButton(O)'}>Circle Button (O)</option>
                                            <option value={'SquareButton'}>Square Button </option>
                                            <option value={'TriangleButton'}>Triangle Button </option>
                                            <option value={'L3Button'}>L3 Button </option>
                                            <option value={'R3Button'}>R3 Button </option>
                                            <option value={'OptionButton'}>Option Button </option>
                                            <option value={'ShareButton'}>Share Button </option>
                                            <option value={'D-padLeft'}>D-pad Left </option>
                                            <option value={'D-padRight'}>D-pad Right </option>
                                            <option value={'D-padUp'}>D-pad Up </option>
                                            <option value={'D-padDown'}>D-pad Down </option>
                                        </select>
                                        <strong className="d-block mb-1">Lower right hand paddle </strong>
                                        <select className="form-control px-2 py-1 w-100 mb-2" name='lowerRight' value={inputPaddle.lowerRight} onChange={(e) => handlePaddle(e)}>
                                            <option value={''}>--Select--</option>
                                            <option value={'CrossButton(X)'}>Cross Button (X)</option>
                                            <option value={'CircleButton(O)'}>Circle Button (O)</option>
                                            <option value={'SquareButton'}>Square Button </option>
                                            <option value={'TriangleButton'}>Triangle Button </option>
                                            <option value={'L3Button'}>L3 Button </option>
                                            <option value={'R3Button'}>R3 Button </option>
                                            <option value={'OptionButton'}>Option Button </option>
                                            <option value={'ShareButton'}>Share Button </option>
                                            <option value={'D-padLeft'}>D-pad Left </option>
                                            <option value={'D-padRight'}>D-pad Right </option>
                                            <option value={'D-padUp'}>D-pad Up </option>
                                            <option value={'D-padDown'}>D-pad Down </option>
                                        </select>
                                        <button className="btn btn-purple" type='button' onClick={() => {saveMappingForm()}}>Save</button>
                                        {fixedMappingErrorMessage && (<p className='formErrors'>{fixedMappingErrorMessage}</p>)}
                                    </>
                                    }
                                </div>
                                </>
                                    : ''
                                }
                                <hr/>
                                {(changeableItem.productType === 'controller' && changeableItem.typeName === 'remap') ? (<h4 className="product-price mb-3"><span>&#x20B9; {addition(changeableItem.salePrice, product?.remap_price)}</span></h4>) : (<h4 className="product-price mb-3">&#x20B9; <span>{changeableItem.salePrice}</span> {product?.price >  changeableItem.sale_price  ? (<span className='discount'>({getPercentage(product?.price, changeableItem.salePrice)}%)</span>) : ''}</h4>) }
                                {(parseInt(product.sale_price) < parseInt(changeableItem.price))  ? <p className='mrpPrice'>MRP <span className='strike'> &#x20B9;{changeableItem.price} </span></p> : ''}
                                {resultMessage && <p className='formSuccess mb-2'>{resultMessage}</p>}
                                <div className="d-block d-lg-flex">
                                    {/* <div className="d-flex justify-content-start align-items-center me-3  mb-3 mb-lg-0">
                                        <button className="qty-btn">+</button>
                                        <input className="qty-btn" type='text' name='quantity'/>
                                        <button className="qty-btn">-</button>
                                    </div> */}                                  
                                    <input type='number' className="cartNumber me-2" min='1' name='productQty' value={inputValues.productQty} onChange={(e) => handleChange(e)}/>
                                    
                                    <button className="btn btn-purple" onClick={addQty} disabled={changeableItem.disableCartButton}>Add To Cart</button>
                                </div>
                                {validation.productQty && <p className='formErrors'>{validation.productQty}</p>}

                                {(product?.stock_quantity && (parseInt(product?.stock_quantity) < 4 && parseInt(product?.stock_quantity) > 0)) && (<h5 className='redText mt-3'>Only {product?.stock_quantity} Left...</h5>)}
                                {frequentlyBought?.length > 0 ? <>
                                    <hr/>
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="h5">Frequently Bought Together</h4>
                                    </div>
                                    <div className="card-body">
                                        <ul className="frequently-products">
                                            <li key={'frequentlyImage'}>
                                                <img src={`${appConstant.IMAGE_URL}/products/` + product?.featured_image} className="attachment-60x60 size-60x60" alt={product?.title}/>
                                            </li>
                                            {frequentlyBought?.map((result, i) =>
                                            <li key={'frequentlyImage' + i}>
                                                <img src={`${appConstant.IMAGE_URL}/products/` + result?.image} className="attachment-60x60 size-60x60" alt={result?.title}/>
                                            </li>
                                            )}
                                        </ul>                
                                        <h4 className="h6 my-3">Buy the whole bundle and save 10%!</h4>
                                        <hr/>
                                        <div className="my-2">
                                            <input className="me-3" type="checkbox" checked={true} disabled={true} name="frequent-products_main" />
                                            <label className="h6 p-0 m-0">{product?.title}</label>
                                            {(changeableItem.productType === 'controller' && changeableItem.typeName === 'remap') ? (<h4 className="h5"><strong>&#x20B9; {addition(changeableItem.salePrice, product?.remap_price)}</strong></h4>) : (<h4 className="h5"><strong>&#x20B9; {changeableItem?.salePrice}</strong></h4>)}
                                        </div>
                                        <hr/>
                                        {frequentlyBought?.map((result, i) =>
                                            (<div key={'frequentlyBought' + i}><div className="my-2">
                                            <input className="me-3" type="checkbox" onChange={(e) => handleFrequentlyChange(e, i)} disabled checked={result?.checked} name="frequent-products" />
                                            <label className="h6 p-0 m-0">{result?.title}</label>
                                            <h4 className="h5"><strong>Rs {result?.salePrice}</strong></h4>
                                        </div>
                                        <hr/> </div>)
                                        )}
                                        <div className="my-2">
                                            <h4 className="h6 m-0">Total Price</h4>
                                            <h4 className="h4"><strong>&#x20B9; {frequentlyTotal()}</strong></h4>
                                        </div>
                                        <div className="discount-message">
                                            10% discount applies to additional items in the pack.
                                        </div>
                                        {resultMessage && <p className='formSuccess mb-2'>{resultMessage}</p>}
                                        <button className="d-block btn btn-purple mt-3" onClick={(e) =>{addQty(e, true)}} disabled={changeableItem.disableCartButton}>Add To Cart</button>
                                    </div>
                                </div>  
                                </>
                                : ''    
                            } 
                            </div>
                        </div>
                    </div>                    
                    <div className="container mb-4"> 
                        <hr className="my-4"/>
                        <div dangerouslySetInnerHTML={{__html: product?.description}}></div>
                    </div>
                    
            </div>
            }
            {product?.related_product?.length > 0 && (<div className="single-product product-section">
                <div className="container">
                    <h4 className="h3 mb-4"><strong>Related Product</strong></h4> 

                     <Carousel
                        responsive={related}
                        showDots={false}
                        autoPlay={true}
                        autoPlaySpeed={3000}
                        infinite
                        arrows={true}                        
                    >
                        {product?.related_product?.length > 0  && product?.related_product?.map((result, i) =>
                            <ProductCard
                            data={result}
                            key={'product'+ i}
                            />
                        )}
                    </Carousel>

                </div>
            </div>)}

            {/* faqs */}
            <div className="faqsSection py-4">       
                <div className="container py-4" >
                    <div className="row">
                        <div className="col-lg-6">
                            <h4 className="h1 mb-2 text-start">FAQs</h4>
                            <h4 className="h6 text-start mb-4">See below our frequently asked question, for more FAQ, please click this <Link className="links" to='/faqs'>Link</Link></h4>
                            <h4 className="h6 text-start mb-5">Our customer support is available Monday to Friday: 9:00am-5:00pm.<br/>
                            Average answer time: 24h
                            </h4>
                            <div className="text-start mb-4">
                            <Link to='/contact-us' className="btn-login">
                                CONTACT US
                            </Link>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <Faqs page={product?.faq_group_id}/>
                        </div>
                    </div>                   
                </div>
            </div>
            <Footer/>
        </>
    );
  }
  
  export default SingleProduct;
  