import React, { useState } from 'react';
import axios from 'axios';
import appConstant from './constant/constant.json';

function Contact() {
    const [resultMessage, setResultMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [inputValues, setInputValues] = useState({
        fname : "",
        phoneNumber : "",
        email : "",
        subject : "",
        message : "",
    });
    const handleChange = (e) => {
        const {name, value} = e.target;
        setInputValues({...inputValues, [name]: value});
    }
    const [validation, setValidation] = useState({
        fname : "",
        phoneNumber : "",
        email : "",
        subject : "",
        message : "",
    });
    const checkValidation = () => {
        let errors = JSON.parse(JSON.stringify(validation));

        //first validation
        const alphabetCond = /^[a-zA-Z ]*$/;
        if (!inputValues.fname) {
        errors.fname = "Full name is required";
        } else if (!inputValues.fname.match(alphabetCond)) {
        errors.fname = "Please enter valid name";
        } else {
        errors.fname = "";
        }
        //number validation
        const numberCond = /^[0-9\b]+$/;
        if (!inputValues.phoneNumber) {
        errors.phoneNumber = "Number is required";
        } else if (!inputValues.phoneNumber.match(numberCond)) {
        errors.phoneNumber = "Please enter valid number";
        } else {
        errors.phoneNumber = "";
        }
        // email validation
        const emailCond = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!inputValues.email) {
          errors.email = "Email is required";
        } else if (!inputValues.email.match(emailCond)) {
          errors.email = "Please enter valid email address";
        } else {
          errors.email = "";
        }
        //subject validation
        if (!inputValues.subject) {
        errors.subject = "Subject is required";
        }else {
        errors.subject = "";
        }
        //message validation
        if (!inputValues.message) {
        errors.message = "Message is required";
        } else {
        errors.message = "";
        }

        setValidation(errors);

        if(errors.fname === '' && errors.phoneNumber === '' && errors.email === '' && errors.subject === '' && errors.message === ''){
          return true;
        }else{
          return false;
        }
    };
    const contactUs = (e) => {
        e.preventDefault();
        const isSuccess = checkValidation();
        if(isSuccess) {
            const contactData = new FormData();
            contactData.append('full_name', inputValues.fname);
            contactData.append('phone_number', inputValues.phoneNumber);
            contactData.append('email', inputValues.email);
            contactData.append('subject', inputValues.subject);
            contactData.append('message', inputValues.message);
            const params = {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*"
            };
            axios.post(`${appConstant.API_URL}/c/contact`, contactData, params)
                .then(res => {
                    setIsLoading(false);
                    if (res?.data?.status === 200) {
                        setResultMessage(res?.data?.message);
                    }else {
                        setResultMessage(res?.data?.message);
                    }
                })
                .catch(error => {
                     setIsLoading(false);
                });
        }
    }
    return (
        <>
            <div className="section-contact">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-10 col-xl-8">
                            <div className="header-section text-center">
                                <h2 className="title">Get In Touch
                                    <span className="dot"></span>
                                    <span className="big-title">CONTACT</span>
                                </h2>
                                <p className="description">Contact us easily with our dedicated form for any inquiries, assistance, or support you may need.</p>
                                
                            </div>
                        </div>
                    </div>
                    <div className="form-contact">
                        <form onSubmit={(e) => {contactUs(e)}}>
                            {resultMessage && <p className='text-center formSuccess mb-2 text-big'>{resultMessage}</p>}
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="single-input">
                                        <i className="fas fa-user"></i>
                                        <input type="text" name="fname" placeholder="ENTER YOUR NAME" value={inputValues.fname} onChange={(e) => handleChange(e)}/>
                                    </div>
                                    {validation.fname && <p className='formErrors mt-1'>{validation.fname}</p>}
                                </div>
                                <div className="col-md-6">
                                    <div className="single-input">
                                        <i className="fas fa-envelope"></i>
                                        <input type="text" name="email" placeholder="ENTER YOUR EMAIL" value={inputValues.email} onChange={(e) => handleChange(e)}/>
                                    </div>
                                    {validation.email && <p className='formErrors mt-1'>{validation.email}</p>}
                                </div>
                                <div className="col-md-6">
                                    <div className="single-input">
                                        <i className="fas fa-phone"></i>
                                        <input type="text" name="phoneNumber" placeholder="ENTER YOUR PHONE NUMBER" value={inputValues.phoneNumber} onChange={(e) => handleChange(e)}/>
                                    </div>
                                    {validation.phoneNumber && <p className='formErrors mt-1'>{validation.phoneNumber}</p>}
                                </div>
                                <div className="col-md-6">
                                    <div className="single-input">
                                        <i className="fas fa-check"></i>
                                        <input type="text" name="subject" placeholder="ENTER YOUR SUBJECT" value={inputValues.subject} onChange={(e) => handleChange(e)}/>
                                    </div>
                                    {validation.subject && <p className='formErrors mt-1'>{validation.subject}</p>}
                                </div>
                                <div className="col-12">
                                    <div className="single-input">
                                        <i className="fas fa-comment-dots"></i>
                                        <textarea placeholder="ENTER YOUR MESSAGE" name='message' value={inputValues.message} onChange={(e) => handleChange(e)}></textarea>
                                    {validation.message && <p className='formErrors'>{validation.message}</p>}
                                    </div>
                                </div>
                                <div className="col-12 text-center py-5 mt-4">
                                {isLoading ?   
                                    <button type="button" className="button"> <span></span> <span></span> <span></span> <span></span>SUBMIT NOW <img className='ms-2' src={`${appConstant.CDN_URL}/assets/images/default.gif`} width={'16px'} height={'16px'} alt={'default'}/></button> : <button type="submit" className="button"> <span></span> <span></span> <span></span> <span></span>SUBMIT NOW </button> 
                                }
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className='personal-details'>
                        <div>
                                <img src={`${appConstant.CDN_URL}/assets/images/email.png`} alt='' /> <a href='mailto:support@sagecontrollers.com'>support@sagecontrollers.com</a>    
                        </div>
                        <div>
                                <img src={`${appConstant.CDN_URL}/assets/images/phone.png`} alt=''/> <a href='tel:+919625554048'>+91-9625554048</a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
  }
  
  export default Contact;
  