import Header from "./shared/Header";
import Footer from "./shared/Footer";
import { Link } from "react-router-dom";
import Banner from "./shared/Banner";
import {useEffect} from "react";
import appConstant from "./shared/constant/constant.json";

function Failed() {
    useEffect(() => {
        localStorage.removeItem('cart');
    }, []);
    return (
        <>
            <Header/>
            <Banner name='order failed' parentSlug='/' parent='Home'/>
            <div className='order-status text-center'>
                <div className="container">
                    <div className='row align-items-center'>
                        <div className='col-md-6 mb-4'>
                            <img className='order-success' src={`${appConstant.CDN_URL}/assets/images/order-failed.png`} alt='order-success'/>
                        </div>
                        <div className='col-md-6'>
                            <h6 className='heading'>Order Failed</h6>
                            <p className='content'> Failed to placed the order. Please try again </p>  
                            <div className="text-center">
                                <Link to='/checkout' className="btn-purple btn mt-4">&#129052; Back to checkout</Link>
                            </div>
                        </div>
                    </div>  
                </div>          
            </div>
            <Footer/>
        </>
    );
  }
  
  export default Failed;