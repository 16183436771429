import React, {useEffect, useState} from 'react';
import Header from "./shared/Header";
import Footer from "./shared/Footer";
import ProductCard from "./shared/ProductCard";
import Banner from "./shared/Banner";
import Faqs from "./shared/Faqs";
import { Link, useParams, } from "react-router-dom";
import axios from "axios";
import appConstant from "./shared/constant/constant.json";
import LoadingSpinner from "./shared/LoadingSpinner";

function ProductCategory() {
    const [isLoading, setIsLoading] = useState(true);
    const props = useParams();
    const [category, setCategory] = useState({});
    const [faqID, setFaqID] = useState('');
    const [selectedMenu, setSelectedMenu] = useState('1');
    useEffect(() => {
        getCategoryData();
        if(props.slug === 'ps5_custom_controllers' || props.slug === 'ps5_limited_edition' ){
            setSelectedMenu('1');
        }else if(props.slug === 'xbox_custom_controllers' || props.slug === 'xbox_limited_edition' ){
            setSelectedMenu('2');
        }else if(props.slug === 'ps4_custom_controllers' || props.slug === 'ps4_limited_edition' ){
            setSelectedMenu('3');
        }else{
            setSelectedMenu('4');
        }

        switch (props.slug) {
            case 'ps5_custom_controllers':
              return setFaqID('5')
            case 'ps5_limited_edition':
                return setFaqID('6')
            case 'xbox_custom_controllers':
              return setFaqID('7')
            case 'xbox_limited_edition':
              return setFaqID('8')
            case 'ps4_custom_controllers':
              return setFaqID('9')
            case 'ps4_limited_edition':
                return setFaqID('10')
            case 'playstation_accessories':
              return setFaqID('11')
            case 'kontrol_freeks':
              return setFaqID('12')
            case 'xbox_accessories':
                return setFaqID('14')
            case 'exclusive_accessories':
                return setFaqID('15')
            default:
              return null
        }
    }, []);
    const params = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    }
    const getCategoryData = () => {
        axios.get(`${appConstant.API_URL}/c/single_category/${props.slug}`, params)
            .then(res => {
                setIsLoading(false);
                if(res?.data?.status === 200){
                    setCategory(res?.data?.data);
                }else{
                    setCategory({});
                }
            })
            .catch(error => {
                setIsLoading(false);
            });
    }
    return (
        <>
            <Header id={selectedMenu}/>
            <Banner name={category.category} parentSlug='/' parent='home' subParent='shop' subParentSlug='/shop'/>
            
            <section className="product-section">
                <div className="container text-center pt-5">
                {isLoading ? <LoadingSpinner/> :
                <>
                    <h4 className="h1 mb-0 text-uppercase">{category.category}</h4>
                    <h4 className="h5">{category.description}</h4>

                    {category?.products?.length > 0 ?
                    <div className="shell">
                        <div className="row">
                        {category?.products?.length > 0  && category?.products?.map((result, i) =>
                            <div className="col-lg-4 col-sm-6 col-12">
                                <ProductCard
                                   data={result}
                                />
                            </div>
                          )}  
                        </div>
                    </div>
                    : 
                    <h3 className='my-5 fw-bold'>No Product Found</h3>}
                    </>
                }
                </div>
            </section>
             {/* faqs */}
             <div className="faqsSection py-4">       
                <div className="container py-4" >
                    <div className="row">
                        <div className="col-lg-6">
                            <h4 className="h1 mb-2 text-start">FAQs</h4>
                            <h4 className="h6 text-start mb-4">See below our frequently asked question, for more FAQ, please click this <Link className="links" to='/faqs'>Link</Link></h4>
                            <h4 className="h6 text-start mb-5">Our customer support is available Monday to Friday: 9:00am-5:00pm.<br/>
                            Average answer time: 24h
                            </h4>
                            <div className="text-start mb-4">
                            <Link to='/contact-us' className="btn-login">
                                CONTACT US
                            </Link>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <Faqs page={faqID}/> 
                        </div>
                    </div>                   
                </div>
            </div> 
            <Footer/>
        </>
    );
  }
  
  export default ProductCategory;
  