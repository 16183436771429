import React, {useEffect, useState} from 'react';
import Header from "./shared/Header";
import Footer from "./shared/Footer";
import Banner from "./shared/Banner";
import BlogCards from "./shared/BlogCards";
import axios from "axios";
import appConstant from "./shared/constant/constant.json";
import LoadingSpinner from './shared/LoadingSpinner.js';

function Blogs() {
    const [isLoadingBlogs, setIsLoadingBlogs] = useState(true);
    const [blogsItem, setBlogsItem] = useState([]);

    useEffect(() => {
        blogs();
    }, []);
    const params = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    }
    const blogs = () => {
     axios.get(`${appConstant.API_URL}/c/blogs`, params)
          .then(res => {
            setIsLoadingBlogs(false);
            if(res?.data?.status === 200){
              setBlogsItem(res?.data?.data);
            }else{
                setBlogsItem([]);
            }
          })
          .catch(error => {
            setIsLoadingBlogs(true);
          });
    }
    return (
        <>
            <Header/>
            <Banner name='Blogs' parentSlug='/' parent='Home'/>
            <section className="product-section my-5">
                <div className="container text-center">
                    <div className="row">
                    {isLoadingBlogs ? (
                        <LoadingSpinner />
                        ) : (
                        <> 
                        {blogsItem?.data?.length > 0  && blogsItem?.data?.map((result, i) =>            
                        <div className="col-lg-4 col-sm-6 col-12 mb-3" key={"blogs" + i}>
                            <BlogCards data={result}/>
                        </div>
                        )}
                        {blogsItem.length  === 0 && (<div className="col-12">Not Found.</div>)}
                        </>
                        )}
                    </div>
                </div>
            </section>
            <Footer/>
        </>
    );
  }
  
  export default Blogs;