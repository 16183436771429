import Header from "./shared/Header";
import Footer from "./shared/Footer";
import Banner from "./shared/Banner";

function AboutUs() {
    return (
        <>
            <Header/>
            <Banner name='About Us' parentSlug='/' parent='Home'/>
            {/* <h4 className="h3 fw-bold mb-2">Who we are</h4> */}
            <div className="container py-5">
                <h4 className="h3 fw-bold mb-2">About Us</h4>
                <h4 className="h6 mb-4">Step into a world where gaming meets artistry. At Sage Controllers, we are a passionate team of gamers who live and breathe the gaming culture. We understand the exhilaration of that perfect headshot, the thrill of a flawless combo, and the joy of immersing ourselves in epic virtual worlds. That's why we founded Sage Controllers – to bring the essence of art and customization to every gamer's hands.</h4>
                <h4 className="h6 mb-4">Sage Controllers is not just a company; it's a sanctuary for passionate gamers like you.
                Sage Controllers is your gateway to an extraordinary gaming experience. As passionate gamers ourselves, we understand the thrill of conquering virtual worlds and the desire to stand out among the elite. With unrivaled expertise and a commitment to excellence, we create personalized controllers that bring your gaming aspirations to life.
                </h4>
                <h4 className="h6 mb-4">Join us on this extraordinary gaming journey and experience the Sage difference. Explore our range of customised controllers, accessories, and enhancements that will take your gaming experience to the next level. Prepare to dominate the virtual battlefield, immerse yourself in breathtaking adventures, and unleash your inner gaming champion with Sage Controllers by your side.</h4>
                <h4 className="h6 mb-4">Embrace the art of gaming. Embrace Sage Controllers.</h4>

                <h4 className="h3 fw-bold mb-2">Vision</h4>
                <h4 className="h6 mb-4">Our vision at Sage Controllers is to be the catalyst that propels gamers to new heights of excellence. We strive to foster a community of passionate gamers who are not only equipped with top-of-the-line gaming gear but are also inspired to push their limits and achieve greatness. We believe that gaming is not just about winning; it's about the journey, the friendships, and the personal growth that comes with it.</h4>
                <h4 className="h6 mb-4">We invite you to explore the world of Sage Controllers and experience gaming like never before. Join us on this thrilling adventure, where gaming becomes art, and every controller becomes a masterpiece. Unleash your true gaming potential with Sage Controllers - because you deserve nothing less than excellence.</h4>

                <h4 className="h3 fw-bold mb-2">Mission</h4>
                <h4 className="h6 mb-4">Our mission is to redefine the gaming experience by creating custom Products that awaken your senses and transport you to realms beyond imagination. We are not merely in the business of customization; we are curators of dreams, We aim to inspire and enable every individual to reach their full potential in the virtual world. By fusing artistry, technology, and an unwavering dedication to our craft, we create custom controllers that become an extension of your identity, fueling your passion and enhancing your gaming prowess.</h4>
                <h4 className="h6 mb-4">Step into the world of Sage Controllers and unlock a gaming experience that transcends the ordinary. Embrace the artistry, innovation, and passion that define our brand. Elevate your gaming prowess with a customized controller that is crafted to perfection and designed to inspire greatness.</h4>
            </div>
            <Footer/>
        </>
    );
  }
  
  export default AboutUs;
  